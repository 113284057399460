import dayjs from 'dayjs';

import { DateFormat, DateType } from '../../App/types';

/**
 * Reformats date value according to the provided source and target formats.
 *
 * @param {DateType} date - Date value to be formatted.
 * @param {DateFormat} sourceFormat - Source format the date is currently in.
 * @param {DateFormat} targetFormat - The date format to use for formatting.
 * @returns {DateType} The formatted date value as a string, or null if date is falsy.
 */
export const reformatDate = (date: DateType, sourceFormat: DateFormat, targetFormat: DateFormat): DateType => {
  if (date && sourceFormat && targetFormat) {
    return dayjs(date, sourceFormat).format(targetFormat);
  }
  return null;
};
