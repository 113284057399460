import Typography, { TypographyProps } from '@mui/material/Typography';
import React from 'react';

/**
 * `TypographyProps<'h6'>`:
 *
 * This is a utility type provided by Material-UI that ensures the props for the `Typography` component align with those of an `h6` element.
 * By passing `TypographyProps<'h6'>`, we explicitly type the props to match what an `h6` element accepts.
 *
 * `Omit<TypographyProps<'h6'>, 'variant'>`:
 *
 * We use `Omit` to exclude the `variant` property from the type, as we are overriding it to always be "subtitle1" in the `H6` component.
 * This avoids TypeScript conflicts when users attempt to pass a different `variant`.
 */
type H6Props = Omit<TypographyProps<'h6'>, 'variant'>;

export const H6: React.FC<H6Props> = (props) => (
  <Typography
    {...props}
    variant="subtitle1"
    component="h6"
    sx={{
      fontWeight: 'bold',
      marginTop: '8px',
      marginBottom: '8px',
    }}
  />
);
