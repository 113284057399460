import { createSelector } from 'reselect';

import { ActionRef, ScreenRef } from '../App/types';
import { getDisplayRuleKey } from '../helpers/displayRules/getDisplayRuleKey';
import { selectDisplayRuleStateMap } from './displayRules/selectDisplayRuleStateMap';
import { selectScreenRefDataMap } from './refdata/selectScreenRefDataMap';

export const selectPageFragmentInitialized = (screen: ScreenRef, action: ActionRef) =>
  createSelector([selectScreenRefDataMap, selectDisplayRuleStateMap], (screenRefDataMap, displayRuleStateMap) => {
    if (!screen || !action) {
      return false;
    }
    const refDataInitialized = !!screenRefDataMap[screen];
    const displayRulesInitialized = !!displayRuleStateMap[getDisplayRuleKey(screen, action)];
    return refDataInitialized && displayRulesInitialized;
  });
