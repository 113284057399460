import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import React, { AnchorHTMLAttributes, ReactNode } from 'react';

export type ExternalLinkIconProps = IconButtonProps & {
  url: AnchorHTMLAttributes<HTMLAnchorElement>['href'];
  children: ReactNode;
};

export const ExternalLinkIcon: React.FC<ExternalLinkIconProps> = (props) => {
  const { url, children, ...rest } = props;
  return (
    <a href={url} target="_blank" rel="noreferrer">
      <IconButton {...rest}>{children}</IconButton>
    </a>
  );
};
