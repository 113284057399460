import React from 'react';

import { HelpTextMarkdown } from '../common/markdown/HelpTextMarkdown';
import { withTermsOfUseText, WithTermsOfUseTextProps } from './withTermsOfUseText';

interface TermsOfUseContentComponentProps extends WithTermsOfUseTextProps {}

export const TermsOfUseContentComponent: React.FC<TermsOfUseContentComponentProps> = ({ text }) => {
  return <HelpTextMarkdown id={'terms-of-use'} text={text} />;
};

export const TermsOfUseContent = withTermsOfUseText(TermsOfUseContentComponent);
