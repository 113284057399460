import Typography, { TypographyProps } from '@mui/material/Typography';
import React from 'react';

import { I18nTypes } from '../../App/types';
import { MuiSxProps as SxProps } from '../../App/types/muiRestrictedTypes';
import { useTranslation } from '../../features/i18n/useTranslation';
import { HelpTextMarkdown } from './markdown/HelpTextMarkdown';

type OverrideSxProps = Omit<SxProps, 'display' | 'flexDirection'>;

interface OverrideProps {
  h4?: OverrideSxProps;
  h5?: OverrideSxProps;
  h6?: OverrideSxProps;
  p?: OverrideSxProps;
}

type FormMarkdownProps = TypographyProps & {
  id: I18nTypes;
  args?: string[];
  overrides?: OverrideProps;
};

export const FormMarkdown: React.FC<FormMarkdownProps> = (props) => {
  const { id, args, overrides = {}, ...rest } = props;
  const text = useTranslation(id, args);

  const baseProps: SxProps = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  };

  return (
    <Typography component={'div'} {...rest}>
      <HelpTextMarkdown
        id={id}
        text={text}
        overrides={{
          h4: {
            ...baseProps,
            ...overrides.h4,
          },
          h5: {
            ...baseProps,
            ...overrides.h5,
          },
          h6: {
            ...baseProps,
            ...overrides.h6,
          },
          p: {
            ...baseProps,
            ...overrides.p,
          },
        }}
      />
    </Typography>
  );
};
