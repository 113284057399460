import React, { useCallback, useMemo } from 'react';

import { isCookieObjectValid } from '../helpers/cookie/isCookieObjectValid';
import { isGoogleAnalyticsAccepted } from '../helpers/cookie/isGoogleAnalyticsAccepted';
import { CookieConsentContext, CookieObject } from './CookieConsentContext';
import { useConsentCookie } from './useConsentCookie';

interface CookieConsentProps {
  consentCookieName?: string;
  consentCookieVersion?: string;
  consentCookieExpiresInDays: number;
  children: React.ReactNode;
}

export const CookieConsentProvider: React.FC<CookieConsentProps> = (props) => {
  const { consentCookieName, consentCookieVersion, consentCookieExpiresInDays, children } = props;
  const [consentCookie, updateConsentCookie] = useConsentCookie(consentCookieName, consentCookieExpiresInDays);

  const accept = useCallback(
    async (value: CookieObject) => {
      updateConsentCookie(value);
    },
    [updateConsentCookie],
  );

  const value = useMemo(
    () => ({
      valid: isCookieObjectValid(consentCookie, consentCookieVersion),
      gaAccepted: isGoogleAnalyticsAccepted(consentCookie),
      accept,
    }),
    [consentCookie, consentCookieVersion, accept],
  );

  return <CookieConsentContext.Provider value={value}>{children}</CookieConsentContext.Provider>;
};
