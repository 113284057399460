import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectProps } from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import React, { FC, useMemo } from 'react';

import { ReferenceData } from '../../../App/apiWrapper';
import { I18nTypes, RefIdType } from '../../../App/types';
import {
  MuiSelectInputProps as SelectInputProps,
  MuiSxProps as SxProps,
  MuiTheme as Theme,
} from '../../../App/types/muiRestrictedTypes';
import { WithDisplayRuleProps } from '../../../features/displayRules/withDisplayRule';
import { useTranslation } from '../../../features/i18n/useTranslation';
import { getViolationItems } from '../../../features/violations/getViolationItems';
import { I18nFragment } from '../I18nFragment';

export type FormSelectProps = SelectProps<RefIdType> &
  Partial<SelectInputProps<RefIdType>> &
  WithDisplayRuleProps & {
    id: I18nTypes;
    placeholder?: I18nTypes;
    showLabel?: boolean;
    emptyOptionId?: I18nTypes;
    options: Array<ReferenceData>;
    selectSx?: SxProps<Theme>;
  };

export const FormSelect: FC<FormSelectProps> = ({
  id,
  placeholder,
  hasViolation,
  violationMessages,
  fullWidth,
  variant,
  onChange,
  value,
  options,
  disabled,
  required,
  emptyOptionId,
  selectSx,
  showLabel = true,
  sx = {},
}) => {
  const labelText = useTranslation(id);
  const placeholderText = useTranslation(placeholder);
  const helperTextItems = useMemo(() => getViolationItems(violationMessages), [violationMessages]);

  const labelId = `select_label_${id}`;

  const defaultInputProps = {
    'data-testid': `select_${id}_input`,
    disabled,
  };
  const selectProps = showLabel
    ? {
        labelId,
        label: labelText,
        inputProps: {
          ...defaultInputProps,
        },
      }
    : {
        displayEmpty: true,
        inputProps: {
          ...defaultInputProps,
          'aria-label': labelText,
        },
      };

  return (
    <FormControl
      fullWidth={fullWidth}
      {...(hasViolation ? { error: true } : {})}
      disabled={disabled}
      required={required}
      sx={{ mt: '16px', mb: '8px', ...sx }}
    >
      {showLabel && <InputLabel id={labelId}>{labelText}</InputLabel>}
      <Select
        id={`select_${id}`}
        aria-describedby={`${id}-helper-text`}
        value={value || ''}
        variant={variant}
        onChange={onChange}
        {...(selectSx ? { sx: selectSx } : {})}
        {...(showLabel ? { labelId } : {})}
        {...(!showLabel ? { MenuProps: { 'aria-label': labelText } } : {})}
        renderValue={(selected) => {
          if (selected.length === 0 && placeholderText) {
            return (
              <Typography component="div" variant={'body1'} sx={{ opacity: 0.42 }}>
                {placeholderText}
              </Typography>
            );
          }
          const option = options.find((option) => option.id === selected);
          return option ? option.name : '';
        }}
        {...selectProps}
      >
        {emptyOptionId && (
          <MenuItem id={`select_menu_item_empty`} key={'select_menu_item_empty'} value={''}>
            <em>
              <I18nFragment id={emptyOptionId} />
            </em>
          </MenuItem>
        )}
        {options.map(({ id, name }) => (
          <MenuItem id={`select_menu_item_${id}`} key={`select_menu_item_${id}`} value={id}>
            {name}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText id={`${id}-helper-text`}>{helperTextItems}</FormHelperText>
    </FormControl>
  );
};
