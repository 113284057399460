import { useEffect, useState } from 'react';

import { useAppSelector } from '../../App/hooks';
import { I18nTypes } from '../../App/types';
import { i18n } from './i18n';
import { selectTranslationEntries } from './selectTranslationEntries';

export const useTranslation = (id?: I18nTypes, args?: string[]) => {
  const [text, setText] = useState<string>('');
  const translationEntries = useAppSelector(selectTranslationEntries);

  useEffect(() => {
    if (id) {
      const text = i18n(id, translationEntries, ...(args || []));
      setText(text);
    }
  }, [translationEntries, id, args]);

  return text;
};
