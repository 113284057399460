import Typography, { TypographyProps } from '@mui/material/Typography';
import React from 'react';

type H5Props = Omit<TypographyProps<'h5'>, 'variant'>;

export const H5: React.FC<H5Props> = (props) => (
  <Typography
    {...props}
    variant="h6"
    component="h5"
    sx={{
      fontWeight: 'bold',
      marginTop: '8px',
      marginBottom: '8px',
    }}
  />
);
