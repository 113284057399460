import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import React, { FC, MouseEvent, useState } from 'react';

import { withDisplayRule } from '../../features/displayRules/withDisplayRule';
import { useTranslation } from '../../features/i18n/useTranslation';
import { FormTextFieldComponent, FormTextFieldProps } from './FormTextField';

export const FormPasswordFieldComponent: FC<FormTextFieldProps> = ({ ...props }) => {
  const { disabled, slotProps = {}, ...rest } = props;
  const { input, ...restSlotProps } = slotProps;

  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseEvents = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const endAdornment = (
    <InputAdornment position="end">
      <IconButton
        disabled={disabled}
        aria-label={useTranslation('SH.ARIA.SHOWHIDEPASSWORD')}
        onClick={handleClickShowPassword}
        onMouseDown={handleMouseEvents}
        onMouseUp={handleMouseEvents}
      >
        {showPassword ? <VisibilityOff /> : <Visibility />}
      </IconButton>
    </InputAdornment>
  );

  return (
    <FormTextFieldComponent
      {...rest}
      disabled={disabled}
      type={showPassword ? 'text' : 'password'}
      slotProps={{
        input: {
          ...input,
          endAdornment,
        },
        ...restSlotProps,
      }}
    />
  );
};

export const FormPasswordField = withDisplayRule(FormPasswordFieldComponent);
