import { styled } from '@mui/material/styles';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import * as React from 'react';

const CustomSvgIcon = styled(SvgIcon, {
  name: 'MaleIcon',
  shouldForwardProp: (_) => true,
})<SvgIconProps>(() => ({
  shapeRendering: 'geometricPrecision',
  textRendering: 'geometricPrecision',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
}));

export const MaleIcon: React.FC<SvgIconProps> = ({ viewBox = '0 0 512 500.462', ...props }) => {
  return (
    <CustomSvgIcon data-testid="MaleIcon" viewBox={viewBox} {...props}>
      <path
        imageRendering="optimizeQuality"
        d="M176.723 304.538c-7.873-8.108-17.766-21.504-19.13-36.46l-1.514.029c-3.484-.047-6.854-.847-10.006-2.645-5.042-2.873-8.591-7.795-10.988-13.352-5.074-11.655-9.096-42.333 3.675-51.102l-2.392-1.59-.269-3.395c-.492-6.174-.617-13.644-.742-21.485-.461-28.844-1.046-63.783-24.233-70.79l-9.951-3.009 6.55-8.111c18.744-23.145 38.319-43.397 58.061-58.941 50.954-40.107 105.847-50.659 150.174 3.228 73.927 7.159 97.553 118.191 46.806 167.224 3.048.112 5.923.813 8.461 2.169 9.666 5.178 9.979 16.405 7.436 25.83-2.509 7.873-5.701 17.008-8.714 24.677-3.652 10.364-9.002 12.295-19.337 11.179-.337 16.436-11.731 30.041-21.567 39.161-3.628 3.363-7.779 6.367-12.332 9.02 5.322 11.712 13.223 19.76 22.832 25.563-4.862 7.097-11.289 13.37-18.733 18.691-18.167 12.986-42.146 20.27-64.81 20.27s-46.643-7.284-64.81-20.27c-6.236-4.456-11.754-9.582-16.242-15.301 7.337-6.955 13.169-16.284 16.906-29.011-5.729-3.309-10.847-7.164-15.131-11.579zm185.463 46.633C422.267 368.294 512 351.278 512 493.88c0 3.626-2.959 6.582-6.592 6.582H6.592c-3.636 0-6.592-2.956-6.592-6.582 0-146.99 96.628-112.639 154.364-135.921 6.226 8.411 14.002 15.824 22.832 22.137 22.233 15.894 51.385 24.807 78.804 24.807 27.419 0 56.571-8.913 78.804-24.807 11.135-7.96 20.597-17.672 27.382-28.925z"
      />
    </CustomSvgIcon>
  );
};
