import React, { ComponentType, useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '../App/hooks';
import { ActionRef, DataState, ScreenRef } from '../App/types';
import { Data, DataObject } from '../helpers/displayRules';
import { DataType } from './displayRules';
import { getDisplayRulesOutputThunk, runMetadataRules, runStateRules } from './displayRules/displayRulesSlice';
import { selectDisplayRulesDataState } from './displayRules/selectDisplayRulesDataState';
import { selectLocale } from './i18n/selectLocale';
import { getReferenceDataMapThunk, setReferenceDataRefresh } from './refdata/refdataSlice';
import { selectRefDataInitialized } from './refdata/selectRefDataInitialized';
import { selectRefDataRefresh } from './refdata/selectRefDataRefresh';

export interface PageFragmentProps {
  screenRef: ScreenRef;
  actionRef: ActionRef;
}

export interface WithPageFragmentProps extends PageFragmentProps {
  doInit?: boolean;

  getData(): DataType;
}

export type PageFragmentInitProps = WithPageFragmentProps & Data;

export const withPageFragmentInit = <T extends PageFragmentInitProps>(Component: ComponentType<T>) => {
  const HOC = (props: T) => {
    const { screenRef, actionRef, getData, doInit = true, ...rest } = props;
    const dispatch = useAppDispatch();
    const locale = useAppSelector(selectLocale);
    const refDataRefresh = useAppSelector(selectRefDataRefresh);
    const refDataInitialized = useAppSelector(selectRefDataInitialized(screenRef));
    const displayRulesDataState = useAppSelector(selectDisplayRulesDataState(screenRef, actionRef));
    const data = getData();

    useEffect(() => {
      if (doInit && locale && (!refDataInitialized || refDataRefresh)) {
        dispatch(setReferenceDataRefresh(false));
        dispatch(getReferenceDataMapThunk({ screenRef, locale }));
      }
    }, [dispatch, refDataInitialized, screenRef, doInit, locale, refDataRefresh]);

    useEffect(() => {
      if (doInit && displayRulesDataState === DataState.INITIAL) {
        dispatch(getDisplayRulesOutputThunk({ screen: screenRef, action: actionRef }));
      }
    }, [dispatch, displayRulesDataState, screenRef, actionRef, doInit]);

    useEffect(() => {
      if (displayRulesDataState === DataState.INITIALIZED) {
        const dataObject = data as unknown as DataObject;
        dispatch(
          runMetadataRules({
            screen: screenRef,
            action: actionRef,
            data: dataObject,
          }),
        );
        dispatch(
          runStateRules({
            screen: screenRef,
            action: actionRef,
            data: dataObject,
          }),
        );
      }
    }, [dispatch, displayRulesDataState, data, screenRef, actionRef]);

    if (!refDataInitialized || refDataRefresh || displayRulesDataState !== DataState.INITIALIZED) {
      return null;
    }

    return <Component {...props} {...rest} />;
  };
  HOC.displayName = `withPageFragmentInit(${Component.displayName || Component.name || 'Component'})`;
  return HOC;
};
