import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Violation } from '../../../App/types';
import { initialState, LoginState } from '.';

const clearViolation = (state: LoginState) => {
  state.violationLevel = null;
  state.violationId = null;
};

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    setEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    setPassword: (state, action: PayloadAction<string>) => {
      state.password = action.payload;
    },
    setViolation: (state, action: PayloadAction<Violation | null>) => {
      const violation = action.payload;
      if (violation) {
        state.violationLevel = violation.violationLevel;
        state.violationId = violation.ruleId;
      } else {
        clearViolation(state);
      }
    },
    resetViolation: (state, _: PayloadAction) => {
      clearViolation(state);
    },
    resetLogin: (state, _: PayloadAction) => {
      Object.assign(state, initialState);
    },
  },
});

export const { setEmail, setPassword, setViolation, resetViolation, resetLogin } = loginSlice.actions;

export default loginSlice.reducer;
