import React from 'react';

import { useTranslation } from '../../../features/i18n/useTranslation';
import { ExternalLinkIcon, ExternalLinkIconProps } from '../../common/ExternalLinkIcon';
import { TelegramIcon } from '../../icons/TelegramIcon';

interface TelegramLinkProps {
  url: ExternalLinkIconProps['url'];
}

export const TelegramLink: React.FC<TelegramLinkProps> = ({ url }) => {
  const ariaLabel = useTranslation('SH.ARIA.TELEGRAMLINK');

  return (
    <ExternalLinkIcon size="large" aria-label={ariaLabel} color="inherit" url={url}>
      <TelegramIcon />
    </ExternalLinkIcon>
  );
};
