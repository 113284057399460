import { createSelector } from 'reselect';

import { ActionRef, ScreenRef } from '../../App/types';
import { getDisplayRulesMetadata } from './getDisplayRulesMetadata';
import { selectDisplayRuleStateMap } from './selectDisplayRuleStateMap';

export const selectFieldMaxLength = (screen: ScreenRef, action: ActionRef, id: string) =>
  createSelector([selectDisplayRuleStateMap], (displayRules) => {
    const currentMetadata = getDisplayRulesMetadata(displayRules, screen, action);
    return currentMetadata ? (currentMetadata[id] ? currentMetadata[id]!.maxLength : null) : null;
  });
