import { styled } from '@mui/material/styles';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import * as React from 'react';

const CustomSvgIcon = styled(SvgIcon, {
  name: 'FemaleIcon',
  shouldForwardProp: (_) => true,
})<SvgIconProps>(() => ({
  '& .cls-1': {
    fillRule: 'evenodd',
  },
}));

export const FemaleIcon: React.FC<SvgIconProps> = ({ viewBox = '0 0 122.88 118.93', ...props }) => {
  return (
    <CustomSvgIcon data-testid="FemaleIcon" viewBox={viewBox} {...props}>
      <path
        className="cls-1"
        d="M30.71,67.85a20.93,20.93,0,0,1-9.77-6.16c6.54-2.46,9.56-9.07,10-20.63.31-8.59-1.47-15.07,1.46-23.55C38.18.72,59.64-5,71.6,4.8,81,3.8,90.42,8.64,92.45,23.1c1.52,10.78-1.7,20.22,1.7,30.16a15.47,15.47,0,0,0,8,9.46c-2.55,2.44-6.25,4.06-10.68,5.14a105,105,0,0,1-16.54,1.85c.46,7.21,1.32,8.7,5.5,11.08-7.53,11.56-31.23,11.41-38,0,3-3.66,4.73-6.42,4.66-10.9-7.13-.33-13.18-1-16.41-2ZM86.56,79.26c5.72,2.2,26.89,8.08,30.56,15,4.15,7.78,3.54,16.43,5.76,24.7H0c2.22-8.27,1.61-16.92,5.76-24.7,3.6-6.76,21.13-11.34,28.15-14,1.37,24.37,54.23,24.4,52.65-.92Z"
      />
    </CustomSvgIcon>
  );
};
