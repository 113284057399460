import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Dayjs } from 'dayjs';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useAppSelector } from '../../../App/hooks';
import { selectAppConfig } from '../../../features/config/selectAppConfig';
import { withDisplayRule } from '../../../features/displayRules/withDisplayRule';
import { selectLocale } from '../../../features/i18n/selectLocale';
import { useTranslation } from '../../../features/i18n/useTranslation';
import { selectDateFormat } from '../../../features/user/selectDateFormat';
import { getViolationItems } from '../../../features/violations/getViolationItems';
import { formatDate } from '../../../helpers/date/formatDate';
import { parseDate } from '../../../helpers/date/parseDate';
import localizationService from '../../../services/LocalizationService';
import { createPopperModifiers } from './createPopperModifiers';
import { FormDatePickerProps } from './FormDatePickerProps';

type FormDatePickerPropsOnChange = FormDatePickerProps['onChange'];

export const FormDatePickerComponent: React.FC<FormDatePickerProps> = (props) => {
  const {
    id,
    value,
    fullWidth,
    handleValueChange,
    displayRuleIndexes,
    hasViolation,
    violationMessages,
    violationLevel,
    actionRef,
    screenRef,
    getData,
    disabled,
    required,
    small,
    clearable = true,
    variant = 'outlined',
    showLabel = true,
    ...rest
  } = props;
  const [cleared, setCleared] = useState<boolean>(false);
  const dateFormat = useAppSelector(selectDateFormat);
  const { dateFormat: serverFormat } = useAppSelector(selectAppConfig);
  const [stateValue, setStateValue] = useState<FormDatePickerProps['value']>(value);
  const locale = useAppSelector(selectLocale);

  useEffect(() => {
    if (cleared) {
      const timeout = setTimeout(() => {
        setCleared(false);
      }, 1500);

      return () => clearTimeout(timeout);
    }
    return () => {};
  }, [cleared]);

  useEffect(() => {
    setStateValue(value);
  }, [value]);

  const onChange: FormDatePickerPropsOnChange = useCallback(
    (newValue: Dayjs | null, { validationError }) => {
      setStateValue(newValue ? newValue.format(dateFormat) : null);
      handleValueChange(formatDate(newValue, serverFormat), !validationError);
    },
    [handleValueChange, dateFormat, serverFormat],
  );

  const labelText = useTranslation(id);
  const helperTextItems = getViolationItems(violationMessages);
  const dateValue = useMemo(() => parseDate(stateValue, dateFormat), [stateValue, dateFormat]);
  const localization = useMemo(() => localizationService.getDatePickerLocalization(locale), [locale]);

  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      adapterLocale={localization.adapterLocale}
      localeText={localization.localeText}
    >
      <DatePicker
        {...rest}
        {...(showLabel ? { label: labelText } : {})}
        format={dateFormat}
        value={dateValue}
        closeOnSelect
        onChange={onChange}
        slotProps={{
          textField: {
            fullWidth: fullWidth === true,
            variant: variant,
            error: hasViolation,
            disabled,
            required,
            ...(small ? { sx: { height: '31px' } } : {}),
            helperText: <>{helperTextItems}</>,
            ...(!showLabel ? { 'aria-label': labelText } : {}),
          },
          ...(clearable ? { field: { clearable: true, onClear: () => setCleared(true) } } : {}),
          popper: {
            modifiers: createPopperModifiers(props),
          },
          openPickerButton: {
            disabled,
          },
          inputAdornment: {},
        }}
      />
    </LocalizationProvider>
  );
};

export const FormDatePicker = withDisplayRule(FormDatePickerComponent);
