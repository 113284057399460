import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  callCreatePerson,
  callDeletePerson,
  callGetPeople,
  callUpdatePerson,
  PeopleFilteringRequest,
  PeopleRequest,
  PeopleResponse,
  PersonItem,
  PersonRequest,
  PersonResponse,
} from '../../App/apiWrapper';
import { changeLanguageThunk } from '../i18n/i18nSlice';
import { initialState, PeopleState } from '.';

export const getPeopleThunk = createAsyncThunk<PeopleResponse, PeopleRequest>('people/search', async (request) => {
  return await callGetPeople(request);
});

export const createPersonThunk = createAsyncThunk<PersonResponse, PersonRequest>('people/create', async (input) => {
  return await callCreatePerson(input);
});

export const updatePersonThunk = createAsyncThunk<PersonResponse, PersonRequest>('people/update', async (input) => {
  return await callUpdatePerson(input);
});

export const deletePersonThunk = createAsyncThunk<PersonResponse, PersonRequest>('people/delete', async (input) => {
  return await callDeletePerson(input);
});

const handleSaveMutatePerson = (state: PeopleState, response: PersonResponse) => {
  const violations = response.violations;
  if (!violations || violations.length === 0) {
    state.createOpen = false;
    state.editOpen = false;
    state.deleteOpen = false;
    state.labelsOpen = false;
    state.refresh = true;
    state.selectedPersonItem = null;
    state.violationLevel = null;
    state.violationId = null;
    state.violationArgs = [];
  } else {
    state.refresh = false;
  }
};

export const peopleSlice = createSlice({
  name: 'people',
  initialState,
  reducers: {
    resetPeople: (state, _: PayloadAction) => {
      Object.assign(state, initialState);
    },
    setPeopleCreateOpen: (state, action: PayloadAction<boolean>) => {
      state.createOpen = action.payload;
    },
    setPeopleEditOpen: (state, action: PayloadAction<boolean>) => {
      state.editOpen = action.payload;
    },
    setPeopleDeleteOpen: (state, action: PayloadAction<boolean>) => {
      state.deleteOpen = action.payload;
    },
    setLabelsOpen: (state, action: PayloadAction<boolean>) => {
      state.labelsOpen = action.payload;
    },
    setPeopleRefresh: (state, action: PayloadAction<boolean>) => {
      state.refresh = action.payload;
    },
    setSelectedPersonItem: (state, action: PayloadAction<PersonItem | null>) => {
      state.selectedPersonItem = action.payload;
    },
    setPeopleFiltering: (state, action: PayloadAction<PeopleFilteringRequest>) => {
      state.filtering = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPeopleThunk.fulfilled, (state, action) => {
        const payload = action.payload;
        const people = payload.people;
        if (people) {
          state.data = people.items;
          state.totalCount = people.totalCount;
          state.limit = people.limit;
          state.offset = people.offset;
        }
      })
      .addCase(createPersonThunk.fulfilled, (state, action) => {
        handleSaveMutatePerson(state, action.payload);
      })
      .addCase(updatePersonThunk.fulfilled, (state, action) => {
        handleSaveMutatePerson(state, action.payload);
      })
      .addCase(deletePersonThunk.fulfilled, (state, action) => {
        handleSaveMutatePerson(state, action.payload);
      })
      .addCase(changeLanguageThunk.fulfilled, (state, action) => {
        const output = action.payload;
        const preferences = output.preferences;
        if (preferences) {
          state.refresh = true;
        }
      });
  },
});

export const {
  resetPeople,
  setPeopleCreateOpen,
  setPeopleEditOpen,
  setPeopleDeleteOpen,
  setLabelsOpen,
  setPeopleRefresh,
  setSelectedPersonItem,
  setPeopleFiltering,
} = peopleSlice.actions;

export default peopleSlice.reducer;
