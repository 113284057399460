import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import React from 'react';

import { AppSpinner } from '../components/common/AppSpinner';
import { withConfig } from '../features/config/withConfig';
import { AppRoutes } from './AppRoutes';
import { theme } from './theme';

export const AppComponent: React.FC = () => (
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <AppRoutes />
    <AppSpinner />
  </ThemeProvider>
);

export const App = withConfig(AppComponent);
