import InfoIcon from '@mui/icons-material/Info';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import useMediaQuery from '@mui/system/useMediaQuery';
import React from 'react';
import { useLocation } from 'react-router';

import { theme } from '../../../App/theme';
import { useTranslation } from '../../../features/i18n/useTranslation';
import { withInfo } from '../../../features/info/withInfo';
import { isValueContainArrayElementPart } from '../../../helpers/isValueContainArrayElementPart';
import { FormTypography } from '../../common/FormTypography';
import { INFO_DIALOG_ID, InfoDialog } from '../../info/InfoDialog';
import { AppInfo } from './AppInfo/AppInfo';
import { LanguageSwitcher } from './LanguageSwitcher';

const LANGUAGE_SWITCHER_EXCLUDES = ['/delete_account_complete'];

const AppHeaderComponent: React.FC = () => {
  const [openInfo, setOpenInfo] = React.useState<boolean>(false);
  const location = useLocation();

  const { pathname } = location;

  const handleCloseInfo = () => {
    setOpenInfo(false);
  };

  const matches = useMediaQuery(theme.breakpoints.up('md'));

  const aboutUs = useTranslation('SH.PG.ABOUTSH');

  return (
    <>
      <Tooltip title={aboutUs}>
        <IconButton
          size="small"
          aria-label={aboutUs}
          aria-controls={INFO_DIALOG_ID}
          aria-haspopup="true"
          onClick={(_) => setOpenInfo(true)}
          color="inherit"
        >
          <InfoIcon />
        </IconButton>
      </Tooltip>
      <Box
        sx={[
          {
            display: 'flex',
            flexGrow: 1,
            color: 'inherit',
            textDecoration: 'none',
            marginTop: '4px',
          },
          matches
            ? {
                ml: 0,
              }
            : {
                ml: 1,
              },
        ]}
      >
        <Stack direction="column" spacing={0}>
          <FormTypography
            id="SH.ISH.HEADER"
            variant={matches ? 'h5' : 'subtitle1'}
            component="h1"
            boxSx={{
              textTransform: 'uppercase',
              wordWrap: 'break-word',
            }}
          />
          <AppInfo />
        </Stack>
      </Box>
      {!isValueContainArrayElementPart(LANGUAGE_SWITCHER_EXCLUDES, pathname) && <LanguageSwitcher />}
      <InfoDialog open={openInfo} onClose={handleCloseInfo} />
    </>
  );
};

export const AppHeader = withInfo(AppHeaderComponent);
