import Dialog, { DialogProps } from '@mui/material/Dialog';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Breakpoint } from '@mui/system/createTheme';
import React from 'react';

import { theme } from '../../../App/theme';

interface ResponsiveDialogProps extends DialogProps {
  breakpoints: {
    maxWidth: Breakpoint;
  };
}

export const ResponsiveDialog: React.FC<ResponsiveDialogProps> = (props) => {
  const { breakpoints, ...rest } = props;
  const {
    // Mobile first
    maxWidth = 'xs',
  } = breakpoints;
  const hasMaxWidthBreakpoint = useMediaQuery(theme.breakpoints.up(maxWidth));

  return <Dialog {...rest} {...(hasMaxWidthBreakpoint ? { maxWidth } : {})} />;
};
