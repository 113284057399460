import { useEffect, useState } from 'react';

import { StringType } from '../App/types';

export const useNavigator = () => {
  const [userAgent, setUserAgent] = useState<StringType>(null);

  useEffect(() => {
    setUserAgent(navigator.userAgent);
  }, [setUserAgent]);

  return {
    userAgent,
  };
};
