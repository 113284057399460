import { createContext } from 'react';

import { LocaleType } from '../App/types';

export interface LocaleContextState {
  update(value: LocaleType): void;
}

const defaultValue: LocaleContextState = {
  update(_: LocaleType): void {},
};

export const LocaleContext = createContext<LocaleContextState>(defaultValue);
