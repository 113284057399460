import { doDelete, doGet, doPost, doPut, fetchData, handleResponse } from './httpClient';
import {
  ActionRef,
  AnnualSign,
  AnnualSignType,
  BooleanType,
  Calendar,
  CalendarSign,
  CodeTableRef,
  CodeTableRefType,
  ColorType,
  DateType,
  DeleteStep,
  Fate,
  FieldViolation,
  HoroscopeType,
  I18nTypes,
  LocaleType,
  MapEntries,
  MapType,
  Marriage,
  NumberType,
  OptionalMapType,
  PersonalImage,
  PersonalImageCountType,
  PsychologicalType,
  RefIdType,
  Rhythm,
  ScreenRef,
  ServerTimeZone,
  SocialType,
  StringType,
  Temperament,
  TermsOfUseStatus,
  ThinkingType,
} from './types';

export interface HasFieldViolations {
  violations: FieldViolation[];
}

interface LoginRequest {
  language: RefIdType;
}

export const callLogin = async (request: LoginRequest, authToken: string): Promise<GenericOutput> =>
  doPost('/api/user/login', request, false, () => authToken);

export const callLogout = async (): Promise<void> => doPost('/api/logout');

export interface User {
  personPk: NumberType;
  firstName: string;
  lastName: string;
  dateOfBirth: DateType;
  gender: RefIdType;
  email: string;
  enabled?: boolean;
  roles?: string[];
}

export interface RegisterRequest extends User {
  password: string;
  matchingPassword: string;
  language: RefIdType;
}

export interface UserResponse extends HasFieldViolations {
  user: User | null;
}

export const callRegisterUser = async (request: RegisterRequest): Promise<UserResponse> =>
  doPost('/api/user/register', request, false);

export interface ForgotPasswordRequest {
  email: string;
}

export interface GenericOutput extends HasFieldViolations {}

export const callForgotPassword = async (request: ForgotPasswordRequest): Promise<GenericOutput> =>
  doPost('/api/user/forgotPassword', request, false);

export const callGetForgotPasswordRequest = async (token: string): Promise<ForgotPasswordRequest> =>
  doGet(`/api/user/forgotPassword/${token}`);

export interface ResetPasswordRequest {
  token?: StringType;
  newPassword: string;
  matchingNewPassword?: string;
}

export const callResetPassword = async (request: ResetPasswordRequest): Promise<GenericOutput> =>
  doPut('/api/user/resetPassword', request);

export interface ChangePasswordRequest {
  oldPassword: string;
  newPassword: string;
  matchingNewPassword: string;
}

export const callChangePassword = async (request: ChangePasswordRequest): Promise<GenericOutput> =>
  doPut('/api/user/changePassword', request);

export interface ThinkingTypeDto {
  gender: RefIdType;
  thinkingType: ThinkingType;
}

export interface MarriageDto {
  partnerDateOfBirth: DateType;
  partnerAnnualSign: AnnualSign | null;
  partnerPersonalImage: PersonalImage | null;
  marriage: Marriage | null;
}

export interface HoroscopeResponse extends HasFieldViolations {
  calendarSign: CalendarSign | null;
  personalImage: PersonalImage | null;
  thinkingTypes: ThinkingTypeDto[];
  temperament: Temperament | null;
  psychologicalType: PsychologicalType | null;
  fate: Fate | null;
  socialType: SocialType | null;
  calendarRhythm: CalendarRhythm | null;
  horoscope: HoroscopeDto | null;
}

export interface HoroscopeInputDto {
  dateOfBirth: DateType;
  partnerDateOfBirth: DateType;
  horoscopeType: HoroscopeType | null;
}

export interface Sign {
  value: AnnualSign;
  contactCount: number;
}

export interface GenderContact {
  gender: RefIdType;
  annualSigns: Sign[];
}

export interface ThinkingTypeContact {
  thinkingType: ThinkingType;
  genderContacts: GenderContact[];
}

export interface TemperamentContact {
  temperament: Temperament;
  annualSigns: Sign[];
}

export interface PsychologicalTypeContact {
  psychologicalType: PsychologicalType;
  annualSigns: Sign[];
}

export interface FateContact {
  fate: Fate;
  annualSigns: Sign[];
}

export interface SocialTypeContact {
  socialType: SocialType;
  annualSigns: Sign[];
}

export interface RhythmContact {
  rhythm: Rhythm;
  annualSigns: Sign[];
}

export interface CalendarRhythm {
  calendar: Calendar;
  annualSignRhythm: Rhythm;
  contacts: RhythmContact[];
}

export interface HoroscopeDto {
  annualSign?: Sign | null;
  vectorMaster?: Sign | null;
  vectorServant?: Sign | null;
  companions?: Sign[] | null;
  clones?: Sign[] | null;
  subordinates?: Sign[] | null;
  advisors?: Sign[] | null;
  calendarRhythm: CalendarRhythm;
  marriage: MarriageDto;
  personalImageContacts: PersonalImageCountType;
  thinkingTypeContacts: ThinkingTypeContact[];
  temperamentContacts: TemperamentContact[];
  psychologicalTypeContacts: PsychologicalTypeContact[];
  fateContacts: FateContact[];
  socialTypeContacts: SocialTypeContact[];
}

export interface HoroscopeSummaryInputDto {
  calendar: Calendar | null;
  partnerDateOfBirth: DateType;
  labels: string[];
}

export interface HoroscopeSummaryDto {
  horoscope: HoroscopeDto;
  labels: string[];
}

export interface HoroscopeSummaryOutput extends HasFieldViolations {
  horoscopeSummary: HoroscopeSummaryDto | null;
}

export const callIndividualStructuralHoroscope = async (request: HoroscopeInputDto): Promise<HoroscopeResponse> =>
  doPut('/api/horoscope/structural/individual', request);

export interface InfoInputDto {
  date: DateType;
  locale?: LocaleType;
}

export interface InfoOutputDto {
  date: DateType;
  dateAnnualSign: ReferenceDataType;
  yearAnnualSign: ReferenceDataType;
}

export const callGetInfo = async (request: InfoInputDto): Promise<InfoOutputDto> => doPut('/api/info', request);

export const callHoroscopeSummary = async (
  personPk: NumberType,
  request: HoroscopeSummaryInputDto,
): Promise<HoroscopeSummaryOutput> => doPost(`/api/person/${personPk}/horoscopesummary`, request);

export const callExportHoroscopePdf = async (personPk: NumberType, locale?: LocaleType): Promise<Blob> => {
  const headers = new Headers();
  headers.append('accept', 'application/pdf');
  const url = locale ? `/api/person/${personPk}/export?locale=${locale}` : `/api/person/${personPk}/export`;
  return fetchData(url, headers, 'GET', (response) => response.blob());
};

export interface PersonRequest {
  personPk?: NumberType;
  firstName: StringType;
  lastName: StringType;
  dateOfBirth: DateType;
  gender: RefIdType;
  labels: string[];
}

export interface Person {
  personPk: NumberType;
  firstName: string;
  lastName: string;
  dateOfBirth: DateType;
  gender: RefIdType;
  calendarSign: CalendarSign | null;
  personalImage: PersonalImage | null;
  thinkingType: ThinkingType | null;
  temperament: Temperament | null;
  psychologicalType: PsychologicalType | null;
  fate: Fate | null;
  socialType: SocialType | null;
  labels: string[];
  horoscope: HoroscopeDto | null;
}

export interface PersonItem {
  personPk: NumberType;
  firstLastName: string;
  firstName: string;
  lastName: string;
  dateOfBirth: DateType;
  gender: RefIdType;
  labels: string[];
  annualSign: StringType;
  calendarSign: StringType;
  personalImage: StringType;
}

export interface PersonResponse {
  person: Person | null;
  violations: FieldViolation[];
}

export interface PagingRequest {
  offset: number;
  limit?: number;
}

export enum SortingDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

export interface SortingColumn {
  id: string;
  direction: SortingDirection;
}

export interface SortingRequest {
  sortingColumns: SortingColumn[];
}

export interface PeopleFilteringRequest {
  personPk?: number;
  firstName?: StringType;
  lastName?: StringType;
  dateOfBirth?: DateType;
  annualSigns: RefIdType[];
  calendarSigns: RefIdType[];
  personalImages: RefIdType[];
  thinkingTypes: RefIdType[];
  temperaments: RefIdType[];
  psychologicalTypes: RefIdType[];
  fates: RefIdType[];
  socialTypes: RefIdType[];
  gender?: RefIdType;
  labels: string[];
}

export interface PeopleRequest {
  paging: PagingRequest;
  sorting: SortingRequest;
  filtering: PeopleFilteringRequest;
}

export interface People {
  items: PersonItem[];
  totalCount: number;
  offset: number;
  limit: number;
}

export interface PeopleResponse {
  people: People;
  violations: FieldViolation[];
}

export interface ContactItemRequest {
  horoscopeType: HoroscopeType;
  paging: PagingRequest;
  filtering: PeopleFilteringRequest;
}

export interface AnnualSignContactItem {
  personPk: number;
  firstLastName: string;
  dateOfBirth: DateType;
  personalImage: StringType;
  labels: string[];
}

export interface AnnualSignContactItemDto {
  horoscopeType: HoroscopeType;
  items: AnnualSignContactItem[];
  annualSign: AnnualSign;
  gender: RefIdType;
  thinkingType: ThinkingType | null;
  temperament: Temperament | null;
  psychologicalType: PsychologicalType | null;
  fate: Fate | null;
  socialType: SocialType | null;
  totalCount: number;
  offset: number;
  limit: number;
}

export interface AnnualSignContactItemResponse {
  dto?: AnnualSignContactItemDto;
  violations: FieldViolation[];
}

export interface PersonalImageContactItem {
  personPk: number;
  firstLastName: string;
  dateOfBirth: DateType;
  annualSign: StringType;
  labels: string[];
}

export interface PersonalImageContactItemDto {
  items: PersonalImageContactItem[];
  personalImage: PersonalImage;
  totalCount: number;
  offset: number;
  limit: number;
}

export interface PersonalImageContactItemResponse {
  dto?: PersonalImageContactItemDto;
  violations: FieldViolation[];
}

export const callCreatePerson = async (request: PersonRequest): Promise<PersonResponse> =>
  doPost('/api/person', request);

export const callGetPerson = async (personPk: number): Promise<PersonResponse> => doGet(`/api/person/${personPk}`);

export const callUpdatePerson = async (request: PersonRequest): Promise<PersonResponse> =>
  doPut(`/api/person/${request.personPk}`, request);

export const callDeletePerson = async (request: PersonRequest): Promise<PersonResponse> =>
  doDelete(`/api/person/${request.personPk}`);

export const callUpdateCurrentUserPersonalInfo = async (request: PersonRequest): Promise<PersonResponse> =>
  doPut('/api/person', request);

export const callGetPeople = async (request: PeopleRequest): Promise<PeopleResponse> => {
  return doPost('/api/people', request);
};

export const callGetAnnualSignContactItems = async (
  personPk: NumberType,
  request: ContactItemRequest,
): Promise<AnnualSignContactItemResponse> => {
  return doPost(`/api/annualsign/${personPk}`, request);
};

export const callGetPersonalImageContactItems = async (
  personPk: NumberType,
  request: ContactItemRequest,
): Promise<PersonalImageContactItemResponse> => {
  return doPost(`/api/personalimage/${personPk}`, request);
};

export interface TranslationsMapEntries {
  key: I18nTypes;
  value: string;
}

export interface TranslationsMap {
  locale: LocaleType;
  entries: TranslationsMapEntries[];
}

export const getTranslationMap = async (locale: LocaleType): Promise<TranslationsMap> =>
  doGet(`/api/translationmap/${locale}`);

export interface UserProfile {
  person: Person | null;
  email: string;
  locale: LocaleType;
  dateFormat: string;
  termsOfUseStatus: TermsOfUseStatus;
  roles: string[];
}

export const getUserProfile = async (): Promise<UserProfile> => doGet('/api/userprofile');

export interface DeleteUserProfileRequest {}

export interface DeleteUserProfileResponse extends HasFieldViolations {
  username: StringType;
  token: StringType;
  deleteStep: DeleteStep;
}

export const callDeleteCurrentUserAccount = async (
  request: DeleteUserProfileRequest,
): Promise<DeleteUserProfileResponse> => doDelete('/api/userprofile', request);

export interface DeleteProfileRequest {
  email: string;
}

export const callGetDeleteProfileRequest = async (token: string): Promise<DeleteProfileRequest> =>
  doGet(`/api/userprofile/deleteProfile/${token}`);

export interface DeleteUserProfileInputDto {
  token?: StringType;
  password: string;
}

export const callDeleteUserProfile = async (request: DeleteUserProfileInputDto): Promise<GenericOutput> =>
  doPut('/api/userprofile/deleteProfile', request);

export interface PreferencesInputDto {
  language: RefIdType;
}

export interface PreferencesDto {
  language: RefIdType;
}

export interface PreferencesOutput extends HasFieldViolations {
  preferences: PreferencesDto | null;
}

export const callChangePreferences = async (request: PreferencesInputDto): Promise<PreferencesOutput> =>
  doPut('/api/user/preferences', request);

export interface ReferenceDataAttribute {
  key: string;
  value: string;
}

export interface ReferenceData {
  id: string;
  name: string;
  attributes?: ReferenceDataAttribute[];
}

export type ReferenceDataType = ReferenceData | null;

export interface ReferenceDataMapEntry {
  key: CodeTableRef;
  value: ReferenceData[];
}

export interface ReferenceDataMap {
  screen: ScreenRef;
  entries: ReferenceDataMapEntry[];
}

export const getReferenceDataMap = async (screenRef: ScreenRef, locale: LocaleType): Promise<ReferenceDataMap> =>
  doGet(`/api/referencedata/${screenRef}/${locale}`);

export const getReferenceDataByCodeTableRefs = async (
  codeTableRefs: CodeTableRef[],
  locale: LocaleType,
): Promise<ReferenceDataMapEntry[]> =>
  doGet(`/api/referencedatabycodetablerefs/${locale}?codeTableRefs=${codeTableRefs.join(',')}`);

export const getLanguageReferenceData = async (): Promise<ReferenceDataMapEntry[]> =>
  doGet('/api/languagereferencedata');

// Display Rules
export enum DisplayRuleType {
  OPTIONAL = 'OPTIONAL',
  MANDATORY = 'MANDATORY',
  HIDDEN = 'HIDDEN',
  READ_ONLY = 'READ_ONLY',
}

export enum FieldType {
  DATE = 'DATE',
  TEXT = 'TEXT',
  TEXT_AREA = 'TEXT_AREA',
  NUMBER = 'NUMBER',
  REFID = 'REFID',
}

export enum RefAttrType {
  SINGLE = 'SINGLE',
  MULTIPLE = 'MULTIPLE',
}

export interface ValueWrapper {
  stringValue: StringType;
  longValue: NumberType;
  integerValue: NumberType;
  decimalValue: NumberType;
}

export interface ForEachDefinition {
  attr: string;
  idxVar: string;
  uiIdxVar: string;
  forEach?: ForEachDefinition | null;
}

export interface FieldDefinition {
  elementId: string;
  forEach?: ForEachDefinition | null;
}

export interface FieldStateDefinition extends FieldState, FieldDefinition {}

export interface FieldState {
  displayRuleType: DisplayRuleType | null;
  codeTables: CodeTableRefType;
}

export interface FieldStateMap {
  [key: string]: FieldState;
}

export interface FieldMetadataDefinition extends FieldMetadataState, FieldDefinition {}

export interface FieldMetadataState {
  type: FieldType;
  maxLength: NumberType;
  maxIntegerPart: NumberType;
  maxFractionPart: NumberType;
  mayBeNegative: BooleanType;
  refAttrType: RefAttrType | null;
  codeTables: CodeTableRefType;
}

export interface FieldMetadataMap {
  [key: string]: FieldMetadataState;
}

export interface ConditionDefinition {
  expression: string;
  variables: MapEntries<VariableMapEntry>;
}

export interface VariableMapEntry {
  key: string;
  value: ValueWrapper;
}

export interface NodeDefinition {
  forEach: ForEachDefinition | null;
  condition: ConditionDefinition;
  branches: NodeDefinition[];
  consequences: FieldStateDefinition[];
}

export interface DisplayRuleDefinition {
  ruleId: string;
  branches: NodeDefinition[];
}

export interface DisplayRuleKey {
  screen: ScreenRef;
  action: ActionRef;
}

export interface DisplayRulesOutput extends DisplayRuleKey {
  defaultState: MapEntries<FieldStateDefinition> | null;
  metadata: MapEntries<FieldMetadataDefinition> | null;
  displayRuleDefs: DisplayRuleDefinition[] | null;
}

export const getDisplayRulesOutput = async (key: DisplayRuleKey): Promise<DisplayRulesOutput> =>
  doGet(`/api/displayRules/${key.screen}/${key.action}`);

export interface DomainGoogleAnalyticsConfig {
  domain: string;
  measurementId: string;
}

export interface GoogleAnalyticsConfig {
  enabled: boolean;
  domainGoogleAnalytics: DomainGoogleAnalyticsConfig[];
  measurementId: string;
}

export interface MetaConfig {
  robots: string;
  title: string;
  subTitle: string;
  description: string;
  keywords: string;
}

export interface SeoConfig {
  defaults: OptionalMapType<LocaleType, MetaConfig>;
  metas: OptionalMapType<LocaleType, OptionalMapType<string, MetaConfig>>;
  preferredDomain: StringType;
}

export interface EnvironmentConfig {
  foundationYear: number;
  introVideoEnabled: boolean;
  systemDateFormat: string;
  consentCookieName: string;
  consentCookieVersion: string;
  consentCookieExpiresInDays: number;
  localeCookieName: string;
  localeCookieExpiresInDays: number;
  systemLocale: LocaleType;
  languageSwitchEnabled: boolean;
  dateFormat: string;
  timeZone: ServerTimeZone | null;
  userRoleNames: string[];
  adminRoleName: StringType;
  registrationExpiryTimeInHours: NumberType;
  resetPasswordExpiryTimeInHours: NumberType;
  forgotPasswordEnabled: boolean;
  deleteAccountEnabled: boolean;
  termsOfUseEnabled: boolean;
  googleAnalytics: GoogleAnalyticsConfig;
  seo: SeoConfig;
  roleMaxContactsCountMapping: MapType<string, number>;
  telegramChannelMapping: OptionalMapType<LocaleType, string>;
}

export const getAppConfig = async (): Promise<EnvironmentConfig> => doGet('/api/config/environment');

export const fetchCsrfInfo = async () => {
  const headers = {
    'cache-control': 'no-cache, no-store, must-revalidate',
    pragma: 'no-cache',
  };
  const endpoint = '/api/config/csrfTokenInfo';
  const response = await fetch(endpoint, { headers });
  return handleResponse(response, endpoint, 'GET', false, (response) => response.json());
};

export const fetchTermsOfUse = async (locale: LocaleType): Promise<string> => {
  const headers = new Headers();
  headers.append('accept', 'text/markdown');
  return fetchData(`/api/termsofuse/${locale}`, headers, 'GET', (response) => response.text());
};

export interface TermsOfUseInfo {
  termsOfUseStatus: TermsOfUseStatus;
}

export const callAcceptTermsOfUse = async (): Promise<TermsOfUseInfo> => doPut('/api/termsofuse');

export const fetchResource = async (path: string): Promise<string> => {
  const headers = new Headers();
  headers.append('accept', 'text/markdown');
  return fetchData(`/api/resources/${path}`, headers, 'GET', (response) => response.text());
};

export interface AnnualSignTypeResource {
  annualSignType: AnnualSignType;
  text: string;
}

export const fetchAnnualSignTypeResource = async (
  locale: LocaleType,
  annualSignType: AnnualSignType,
): Promise<AnnualSignTypeResource> => {
  return doGet(`/api/resources/annualsigntype/${locale}/${annualSignType}`);
};

export interface PersonalImageResource {
  personalImage: PersonalImage;
  text: string;
}

export const fetchPersonalImageResource = async (
  locale: LocaleType,
  personalImage: PersonalImage,
): Promise<PersonalImageResource> => {
  return doGet(`/api/resources/personalimage/${locale}/${personalImage}`);
};

export interface ThinkingTypeResource {
  thinkingType: ThinkingType;
  text: string;
}

export const fetchThinkingTypeResource = async (
  locale: LocaleType,
  thinkingType: ThinkingType,
): Promise<ThinkingTypeResource> => {
  return doGet(`/api/resources/thinkingtype/${locale}/${thinkingType}`);
};

export interface TemperamentResource {
  temperament: Temperament;
  text: string;
}

export const fetchTemperamentResource = async (
  locale: LocaleType,
  temperament: Temperament,
): Promise<TemperamentResource> => {
  return doGet(`/api/resources/temperament/${locale}/${temperament}`);
};

export interface PsychologicalTypeResource {
  psychologicalType: PsychologicalType;
  text: string;
}

export const fetchPsychologicalTypeResource = async (
  locale: LocaleType,
  psychologicalType: PsychologicalType,
): Promise<PsychologicalTypeResource> => {
  return doGet(`/api/resources/psychologicaltype/${locale}/${psychologicalType}`);
};

export interface FateResource {
  fate: Fate;
  text: string;
}

export const fetchFateResource = async (locale: LocaleType, fate: Fate): Promise<FateResource> => {
  return doGet(`/api/resources/fate/${locale}/${fate}`);
};

export interface SocialTypeResource {
  socialType: SocialType;
  text: string;
}

export const fetchSocialTypeResource = async (
  locale: LocaleType,
  socialType: SocialType,
): Promise<SocialTypeResource> => {
  return doGet(`/api/resources/socialtype/${locale}/${socialType}`);
};

export interface MarriageResource {
  marriage: Marriage;
  text: string;
}

export const fetchMarriageResource = async (locale: LocaleType, marriage: Marriage): Promise<MarriageResource> => {
  return doGet(`/api/resources/marriage/${locale}/${marriage}`);
};

export interface RhythmResource {
  rhythm: Rhythm;
  text: string;
}

export const fetchRhythmResource = async (locale: LocaleType, rhythm: Rhythm): Promise<RhythmResource> => {
  return doGet(`/api/resources/rhythm/${locale}/${rhythm}`);
};

export interface LabelItem {
  labelPk: NumberType;
  name: string;
  description: StringType;
  color: ColorType;
  peopleCount: number;
}

export interface Labels {
  items: LabelItem[];
  totalCount: number;
  offset: number;
  limit: number;
}

export interface LabelsRequest {
  paging: PagingRequest;
}

export interface LabelsResponse {
  labels: Labels;
  violations: FieldViolation[];
}

export const callGetLabels = async (request: LabelsRequest): Promise<LabelsResponse> => doPost('/api/labels', request);

export interface LabelRequest {
  labelPk: NumberType;
  name: string;
  description: StringType;
  color: ColorType;
}

export interface Label {
  labelPk: number;
  name: string;
  description: StringType;
  color: ColorType;
}

export interface LabelResponse {
  label: Label | null;
  violations: FieldViolation[];
}

export const callCreateLabel = async (request: LabelRequest): Promise<LabelResponse> => doPost('/api/label', request);

export const callUpdateLabel = async (request: LabelRequest): Promise<LabelResponse> =>
  doPut(`/api/label/${request.labelPk}`, request);

export const callDeleteLabel = async (request: LabelRequest): Promise<LabelResponse> =>
  doDelete(`/api/label/${request.labelPk}`);
