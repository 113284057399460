import { createSelector } from '@reduxjs/toolkit';

import { TermsOfUseStatus } from '../../App/types';
import { selectAppConfig } from '../config/selectAppConfig';
import { selectUiState } from './selectUiState';

export const selectTermsOfUseStatus = createSelector(
  [selectAppConfig, selectUiState],
  ({ termsOfUseEnabled }, { termsOfUseStatus }) => (termsOfUseEnabled ? termsOfUseStatus : TermsOfUseStatus.ACCEPTED),
);
