import Typography, { TypographyProps } from '@mui/material/Typography';
import React from 'react';

type ParagraphProps = Omit<TypographyProps<'div'>, 'variant'>;

export const Paragraph: React.FC<ParagraphProps> = (props) => (
  <Typography
    {...props}
    variant="body2"
    component="div"
    sx={{
      marginTop: '16px',
      marginBottom: '16px',
    }}
  />
);
