import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import Grid from '@mui/material/Grid2';
import Stack from '@mui/material/Stack';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { useCallback } from 'react';

import { useAppDispatch, useAppSelector } from '../../App/hooks';
import { theme } from '../../App/theme';
import { TermsOfUseStatus } from '../../App/types';
import { selectLocale } from '../../features/i18n/selectLocale';
import { selectTermsOfUseStatus } from '../../features/ui/selectTermsOfUseStatus';
import { acceptTermsOfUseThunk } from '../../features/ui/uiSlice';
import { DefaultFormButton } from '../common/DefaultFormButton';
import { I18nFragment } from '../common/I18nFragment';
import { PRIVACY_POLICY_PATH } from '../privacyPolicy/types';

interface TermsOfUseDialogActionsProps {
  onDisagree(): void;
}

export const TermsOfUseDialogActions: React.FC<TermsOfUseDialogActionsProps> = ({ onDisagree }) => {
  const dispatch = useAppDispatch();
  const termsOfUseStatus = useAppSelector(selectTermsOfUseStatus);
  const locale = useAppSelector(selectLocale);

  const matches = useMediaQuery(theme.breakpoints.up('lg'));

  const onAgree = useCallback(() => {
    dispatch(acceptTermsOfUseThunk());
  }, [dispatch]);

  if (termsOfUseStatus === TermsOfUseStatus.UNKNOWN || termsOfUseStatus === TermsOfUseStatus.ACCEPTED) {
    return null;
  }

  return (
    <DialogActions
      sx={{
        justifyContent: {
          xs: 'center',
          lg: 'flex-end',
        },
      }}
    >
      <Grid container spacing={1}>
        <Grid
          size={{
            xs: 12,
            lg: 6,
          }}
        >
          <Button
            href={`${PRIVACY_POLICY_PATH}/${locale}`}
            target="_blank"
            rel="noreferrer"
            variant="contained"
            color="inherit"
            {...(!matches ? { fullWidth: true } : {})}
            sx={{
              textAlign: 'center',
              width: {
                lg: 'max-content',
              },
            }}
          >
            <I18nFragment id="SH.PG.PRIVACYPOLICY" />
          </Button>
        </Grid>
        <Grid
          size={{
            xs: 12,
            lg: 6,
          }}
        >
          <Stack
            direction="row"
            spacing={2}
            sx={{
              display: 'flex',
              justifyContent: {
                xs: 'center',
                lg: 'flex-end',
              },
            }}
          >
            <DefaultFormButton id="SH.BTN.DISAGREE" color="primary" onClick={onDisagree} />
            <DefaultFormButton id="SH.BTN.AGREE" variant="contained" color="primary" onClick={onAgree} />
          </Stack>
        </Grid>
      </Grid>
    </DialogActions>
  );
};
