import { ControlPosition } from 'react-draggable';

import { AnnualSignContactItem, MarriageDto, Person, PersonalImageContactItem, Sign } from '../../App/apiWrapper';
import {
  Calendar,
  DateType,
  Fate,
  HoroscopeType,
  OptionalMapType,
  PersonalImage,
  PsychologicalType,
  RefIdType,
  Rhythm,
  SocialType,
  Temperament,
  ThinkingType,
} from '../../App/types';

export interface AnnualSignContactsState {
  initialized: boolean;
  open: boolean;
  filterOpened: boolean;
  labels: string[];
  items: AnnualSignContactItem[];
}

export type AnnualSignContactsMap = {
  [key in string]: AnnualSignContactsState;
};

export type AnnualSignPopperPositionMap = {
  [key in string]: ControlPosition;
};

export interface PersonalImageContactsState {
  initialized: boolean;
  open: boolean;
  filterOpened: boolean;
  labels: string[];
  items: PersonalImageContactItem[];
}

export type PersonalImageContactsMap = {
  [key in PersonalImage]: PersonalImageContactsState;
};

export type PersonalImagePopperPositionMap = {
  [key in PersonalImage]: ControlPosition;
};

export interface FilterState {
  byLabelOpened: boolean;
  labels: string[];
}

export interface MagicCalendarState {
  calendar: Calendar | null;
}

export interface MarriageState {
  partnerDateOfBirth: DateType;
}

export interface SignState extends Sign {
  filteredContactCount?: number | null;
}

interface GenderContactState {
  gender: RefIdType;
  annualSigns: SignState[];
}

interface ThinkingTypeContactState {
  thinkingType: ThinkingType;
  genderContacts: GenderContactState[];
}

interface TemperamentContactState {
  temperament: Temperament;
  annualSigns: SignState[];
}

interface PsychologicalTypeContactState {
  psychologicalType: PsychologicalType;
  annualSigns: SignState[];
}

interface FateContactState {
  fate: Fate;
  annualSigns: SignState[];
}

interface SocialTypeContactState {
  socialType: SocialType;
  annualSigns: SignState[];
}

export interface RhythmContactState {
  rhythm: Rhythm;
  annualSigns: SignState[];
}

interface ContactsCountState {
  contactCount: number;
  filteredContactCount?: number;
}

export const contactsCountInitialState: ContactsCountState = {
  contactCount: 0,
};

export type PersonalImageCountStateType = OptionalMapType<PersonalImage, ContactsCountState>;

export interface CalendarRhythmState {
  calendar: Calendar;
  annualSignRhythm: Rhythm;
  contacts: RhythmContactState[];
}

export interface PersonHoroscopeState {
  annualSign?: SignState | null;
  vectorMaster?: SignState | null;
  vectorServant?: SignState | null;
  companions?: SignState[] | null;
  clones?: SignState[] | null;
  subordinates?: SignState[] | null;
  advisors?: SignState[] | null;
  calendarRhythm?: CalendarRhythmState;
  marriage: MarriageDto;
  personalImageContacts: PersonalImageCountStateType;
  thinkingTypeContacts: ThinkingTypeContactState[];
  temperamentContacts: TemperamentContactState[];
  psychologicalTypeContacts: PsychologicalTypeContactState[];
  fateContacts: FateContactState[];
  socialTypeContacts: SocialTypeContactState[];
}

export interface PersonState {
  horoscopeType: HoroscopeType;
  filter: FilterState;
  magicCalendar: MagicCalendarState;
  marriage: MarriageState;
  person: Omit<Person, 'horoscope'>;
  horoscope: PersonHoroscopeState;
  annualSignContactsMap: AnnualSignContactsMap;
  annualSignPopperPositionMap: AnnualSignPopperPositionMap;
  personalImageContactsMap: PersonalImageContactsMap;
  personalImagePopperPositionMap: PersonalImagePopperPositionMap;
}

export const popperPositionInitialState: ControlPosition = {
  x: 0,
  y: 0,
};

export const annualSignContactsInitialState: AnnualSignContactsState = {
  initialized: false,
  open: false,
  filterOpened: false,
  labels: [],
  items: [],
};

export const annualSignContactsMapInitialState: AnnualSignContactsMap = {};

export const annualSignPopperPositionMapInitialState: AnnualSignPopperPositionMap = {};

export const personalImageContactsInitialState: PersonalImageContactsState = {
  initialized: false,
  open: false,
  filterOpened: false,
  labels: [],
  items: [],
};

export const personalImageContactsMapInitialState: PersonalImageContactsMap = {
  [PersonalImage.ARISTOCRAT]: { ...personalImageContactsInitialState },
  [PersonalImage.PROFESSOR]: { ...personalImageContactsInitialState },
  [PersonalImage.CHIEF]: { ...personalImageContactsInitialState },
  [PersonalImage.VECTOR]: { ...personalImageContactsInitialState },
  [PersonalImage.KNIGHT]: { ...personalImageContactsInitialState },
  [PersonalImage.JOKER]: { ...personalImageContactsInitialState },
  [PersonalImage.KING]: { ...personalImageContactsInitialState },
};

export const personalImagePopperPositionMapInitialState: PersonalImagePopperPositionMap = {
  [PersonalImage.ARISTOCRAT]: { ...popperPositionInitialState },
  [PersonalImage.PROFESSOR]: { ...popperPositionInitialState },
  [PersonalImage.CHIEF]: { ...popperPositionInitialState },
  [PersonalImage.VECTOR]: { ...popperPositionInitialState },
  [PersonalImage.KNIGHT]: { ...popperPositionInitialState },
  [PersonalImage.JOKER]: { ...popperPositionInitialState },
  [PersonalImage.KING]: { ...popperPositionInitialState },
};

export const initialState: PersonState = {
  horoscopeType: HoroscopeType.BUSINESS_PYRAMID,
  filter: {
    byLabelOpened: false,
    labels: [],
  },
  magicCalendar: {
    calendar: null,
  },
  marriage: {
    partnerDateOfBirth: null,
  },
  person: {
    personPk: null,
    firstName: '',
    lastName: '',
    dateOfBirth: null,
    gender: null,
    calendarSign: null,
    personalImage: null,
    thinkingType: null,
    temperament: null,
    psychologicalType: null,
    fate: null,
    socialType: null,
    labels: [],
  },
  horoscope: {
    marriage: {
      partnerDateOfBirth: null,
      marriage: null,
      partnerAnnualSign: null,
      partnerPersonalImage: null,
    },
    personalImageContacts: {},
    thinkingTypeContacts: [],
    temperamentContacts: [],
    psychologicalTypeContacts: [],
    fateContacts: [],
    socialTypeContacts: [],
  },
  annualSignContactsMap: annualSignContactsMapInitialState,
  annualSignPopperPositionMap: annualSignPopperPositionMapInitialState,
  personalImageContactsMap: personalImageContactsMapInitialState,
  personalImagePopperPositionMap: personalImagePopperPositionMapInitialState,
};
