import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Grid from '@mui/material/Grid2';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { useCallback, useMemo, useState } from 'react';

import { useAppSelector } from '../../../App/hooks';
import { theme } from '../../../App/theme';
import { I18nTypes } from '../../../App/types';
import { selectAppConfig } from '../../../features/config/selectAppConfig';
import { selectGoogleAnalytics } from '../../../features/config/selectGoogleAnalytics';
import { selectLocale } from '../../../features/i18n/selectLocale';
import { hasOptionalCookies } from '../../../helpers/cookie/hasOptionalCookies';
import { CookieObject } from '../../../hooks/CookieConsentContext';
import { useCookieConsent } from '../../../hooks/useCookieConsent';
import { FormTypography } from '../../common/FormTypography';
import { I18nFragment } from '../../common/I18nFragment';
import { LanguageSwitcher } from '../AppBar/LanguageSwitcher';
import { CookieConsentMessageContent } from './CookieConsentMessageContent';
import { COOKIE_POLICY_PATH } from './types';

interface CookieSettingsModalProps {
  show: boolean;
  defaultGaChecked: boolean;
  showAcceptButton: boolean;
  acceptId: I18nTypes;

  setShow(value: boolean): void;

  onClose?(): void;
}

export const COOKIE_CONSENT_DIALOG_ID = 'cookie-consent-dialog';

export const CookieConsentDialog: React.FC<CookieSettingsModalProps> = (props) => {
  const { show, defaultGaChecked, showAcceptButton, acceptId, setShow, onClose } = props;
  const { consentCookieName, consentCookieVersion } = useAppSelector(selectAppConfig);
  const { accept } = useCookieConsent();
  const [gaChecked, setGaChecked] = useState(defaultGaChecked);
  const locale = useAppSelector(selectLocale);
  const googleAnalytics = useAppSelector(selectGoogleAnalytics);

  const onAcceptCookies = useCallback(() => {
    if (consentCookieName && consentCookieVersion) {
      const consents: CookieObject['consents'] = {
        mandatory: true,
      };
      if (googleAnalytics.enabled) {
        consents.googleAnalytics = gaChecked;
      }
      const cookieObject: CookieObject = {
        version: consentCookieVersion,
        consents,
      };
      accept(cookieObject);
      setShow(false);
    }
  }, [consentCookieName, consentCookieVersion, setShow, googleAnalytics, gaChecked, accept]);

  const optionalCookies = useMemo(() => hasOptionalCookies(googleAnalytics), [googleAnalytics]);

  const matches = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Dialog
      id={COOKIE_CONSENT_DIALOG_ID}
      open={show}
      aria-labelledby="cookie-consent-dialog-title"
      sx={[matches ? { minWidth: '620px' } : {}]}
      {...(onClose ? { onClose } : {})}
    >
      <DialogTitle sx={{ fontWeight: 'bold' }} id="cookie-consent-dialog-title">
        <Grid
          container
          direction="row"
          spacing={1.5}
          sx={{
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Grid>
            <I18nFragment id="SH.CKCNSNT.HEADER" />
          </Grid>
          <Grid>
            <LanguageSwitcher />
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent
        sx={{
          pt: 0,
          pb: 0,
        }}
      >
        <CookieConsentMessageContent />
        {optionalCookies && (
          <Stack sx={{ mb: 1 }} spacing={0}>
            <FormTypography
              variant={'body1'}
              component={'span'}
              id="SH.CKCNSNT.OPTIONALCOOKIES"
              sx={{ fontWeight: 'bold' }}
            />
            <FormGroup>
              {googleAnalytics.enabled && (
                <FormControlLabel
                  control={<Checkbox checked={gaChecked} onChange={(_) => setGaChecked(!gaChecked)} />}
                  label={
                    <Typography variant={'body2'} component={'span'}>
                      Google Analytics
                    </Typography>
                  }
                />
              )}
            </FormGroup>
          </Stack>
        )}
      </DialogContent>
      <Stack
        spacing={1}
        sx={{
          p: 1,
          textAlign: 'center',
        }}
      >
        <Button
          href={`${COOKIE_POLICY_PATH}/${locale}`}
          target="_blank"
          rel="noreferrer"
          variant="contained"
          fullWidth
          color="inherit"
        >
          <I18nFragment id="SH.BTN.COOKIEPOLICY" />
        </Button>
        {showAcceptButton && (
          <Button variant="contained" fullWidth onClick={onAcceptCookies} color="primary">
            <I18nFragment id={acceptId} />
          </Button>
        )}
        {onClose && (
          <Button variant="contained" fullWidth onClick={onClose} color="primary">
            <I18nFragment id="SH.BTN.CLOSE" />
          </Button>
        )}
      </Stack>
    </Dialog>
  );
};
