import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  callDeleteCurrentUserAccount,
  callUpdateCurrentUserPersonalInfo,
  DeleteUserProfileRequest,
  DeleteUserProfileResponse,
  getUserProfile,
  Person,
  PersonRequest,
  PersonResponse,
  UserProfile,
} from '../../App/apiWrapper';
import { DeleteStep, ViolationLevel } from '../../App/types';
import { initialState, UserState } from '.';

export const getUserProfileThunk = createAsyncThunk<UserProfile>('user/profile', async (_) => {
  return await getUserProfile();
});

export const updateCurrentUserPersonalInfoThunk = createAsyncThunk<PersonResponse, PersonRequest>(
  'user/person',
  async (input) => {
    return await callUpdateCurrentUserPersonalInfo(input);
  },
);

export const deleteCurrentUserAccountThunk = createAsyncThunk<DeleteUserProfileResponse, DeleteUserProfileRequest>(
  'user/delete',
  async (input) => {
    return await callDeleteCurrentUserAccount(input);
  },
);

const updateUserState = (state: UserState, person: Person | null) => {
  if (person) {
    state.personPk = person.personPk;
    state.firstName = person.firstName;
    state.lastName = person.lastName;
    state.dateOfBirth = person.dateOfBirth;
    state.gender = person.gender;
    state.calendarSign = person.calendarSign;
    state.personalImage = person.personalImage;
    state.labels = person.labels;
    const horoscope = person.horoscope;
    if (horoscope) {
      state.annualSign = horoscope.annualSign;
    }
  }
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    resetUser: (state, _: PayloadAction) => {
      Object.assign(state, initialState);
    },
    resetViolation: (state, _: PayloadAction) => {
      state.violationLevel = null;
      state.violationId = null;
      state.violationArgs = [];
    },
    setUserEditOpen: (state, action: PayloadAction<boolean>) => {
      state.editOpen = action.payload;
    },
    setUserDeleteOpen: (state, action: PayloadAction<boolean>) => {
      state.deleteOpen = action.payload;
    },
    setUserDeleteStep: (state, action: PayloadAction<DeleteStep>) => {
      state.deleteStep = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserProfileThunk.fulfilled, (state, action) => {
        const profile = action.payload;
        const { person, email, dateFormat, locale, roles } = profile;
        state.email = email;
        state.dateFormat = dateFormat;
        state.locale = locale;
        state.roles = roles;
        updateUserState(state, person);
        state.initialized = true;
      })
      .addCase(updateCurrentUserPersonalInfoThunk.fulfilled, (state, action) => {
        const response = action.payload;
        const violations = response.violations;
        if (!violations || violations.length === 0) {
          updateUserState(state, response.person);
          state.violationLevel = ViolationLevel.INFO;
          state.violationId = 'SH.PRFL.IR.001';
        }
      })
      .addCase(deleteCurrentUserAccountThunk.fulfilled, (state, action) => {
        const response = action.payload;
        state.deleteStep = response.deleteStep;
      });
  },
});

export const { resetUser, setUserEditOpen, setUserDeleteOpen, setUserDeleteStep, resetViolation } = userSlice.actions;

export default userSlice.reducer;
