import React, { JSX } from 'react';

import { clonePropertyInReactElement } from './clonePropertyInReactElement';

export const clonePropertyInReactElementChildren = (
  source: JSX.Element,
  sourceProperty: string,
  targetProperty: string,
) => {
  const children: React.ReactNode[] = [];

  React.Children.toArray(source.props.children).forEach((value) => {
    if (React.isValidElement(value)) {
      const clonedChild = clonePropertyInReactElement(value, sourceProperty, targetProperty);
      children.push(clonedChild);
    } else {
      children.push(value);
    }
  });
  return React.cloneElement(source, {}, ...children);
};
