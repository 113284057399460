import { ManipulateType } from 'dayjs';

import { ReferenceDataType } from '../../App/apiWrapper';
import { DateType } from '../../App/types';

export interface TimeoutConfig {
  value: number;
  unit: ManipulateType;
}

export interface InfoState {
  date: DateType;
  dateAnnualSign: ReferenceDataType;
  yearAnnualSign: ReferenceDataType;
  timeoutConfig: TimeoutConfig;
  initialized: boolean;
}

export const initialState: InfoState = {
  date: null,
  dateAnnualSign: null,
  yearAnnualSign: null,
  timeoutConfig: {
    value: 1,
    unit: 'day',
  },
  initialized: false,
};
