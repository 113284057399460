import { LocaleType } from '../../App/types';
import { LanguageReferenceData } from '.';

export const getLanguageByLocale = (
  languages: LanguageReferenceData[],
  locale?: LocaleType,
): LanguageReferenceData | null => {
  if (languages.length === 0 || !locale) {
    return null;
  }
  return languages.find((value) => value.locale === locale) || null;
};
