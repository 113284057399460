import React from 'react';

import { HelpTextMarkdown } from '../../common/markdown/HelpTextMarkdown';
import { withCookieConsentMessageText, WithCookieConsentMessageTextProps } from './withCookieConsentMessageText';

interface CookieConsentMessageContentProps extends WithCookieConsentMessageTextProps {}

export const CookieConsentMessageContentComponent: React.FC<CookieConsentMessageContentProps> = ({ text }) => {
  return <HelpTextMarkdown text={text} />;
};

export const CookieConsentMessageContent = withCookieConsentMessageText(CookieConsentMessageContentComponent);
