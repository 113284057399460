import Link from '@mui/material/Link';
import React, { useCallback } from 'react';

import { useAppDispatch } from '../../../App/hooks';
import { setShowAboutUs } from '../../../features/ui/uiSlice';
import { ABOUT_US_DIALOG_ID } from '../../aboutUs/AboutUsDialog';
import { I18nFragment } from '../../common/I18nFragment';

export const AboutUsLink: React.FC = () => {
  const dispatch = useAppDispatch();

  const showAboutUs = useCallback(() => {
    dispatch(setShowAboutUs(true));
  }, [dispatch]);

  return (
    <Link
      variant="body2"
      component="button"
      aria-controls={ABOUT_US_DIALOG_ID}
      aria-haspopup="true"
      onClick={showAboutUs}
    >
      <I18nFragment id="SH.PG.ABOUTUS" />
    </Link>
  );
};
