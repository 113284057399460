import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import Tab from '@mui/material/Tab';
import * as React from 'react';
import { useCallback, useEffect, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '../../App/hooks';
import { ActionRef, ScreenRef } from '../../App/types';
import { useTranslation } from '../../features/i18n/useTranslation';
import { selectPageFragmentInitialized } from '../../features/selectPageFragmentInitialized';
import { selectShowError } from '../../features/ui/selectShowError';
import { selectChangePassword } from '../../features/user/changePassword/selectChangePassword';
import { selectUserProfile } from '../../features/user/selectUserProfile';
import { updateCurrentUserPersonalInfoThunk } from '../../features/user/userSlice';
import { ChangePasswordDialogFragment } from './ChangePasswordDialogFragment';
import { PersonDialogFragment, PersonInput } from './PersonDialogFragment';

interface EditAccountDialogProps {
  open: boolean;

  handleCancel(): void;
}

const EDIT_PROFILE_TAB = '1';
const CHANGE_PASSWORD_TAB = '2';

export const EDIT_PROFILE_DIALOG_ID = 'edit-profile-dialog';

export const EditAccountDialog: React.FC<EditAccountDialogProps> = ({ open, handleCancel }) => {
  const dispatch = useAppDispatch();
  const userProfile = useAppSelector(selectUserProfile);
  const changePassword = useAppSelector(selectChangePassword);
  const showError = useAppSelector(selectShowError);

  const personDialogProps = useMemo(
    () => ({
      screenRef: ScreenRef.PERSON,
      actionRef: ActionRef.UPDATE,
    }),
    [],
  );
  const personDialogInitialized = useAppSelector(
    selectPageFragmentInitialized(personDialogProps.screenRef, personDialogProps.actionRef),
  );

  const changePasswordDialogProps = {
    screenRef: ScreenRef.CHANGE_PASSWORD,
    actionRef: ActionRef.CHANGE_PASSWORD,
  };
  const changePasswordDialogInitialized = useAppSelector(
    selectPageFragmentInitialized(changePasswordDialogProps.screenRef, changePasswordDialogProps.actionRef),
  );

  const getPerson = useCallback(() => userProfile, [userProfile]);
  const getChangePassword = useCallback(() => changePassword, [changePassword]);

  const { violationId, violationLevel, violationArgs } = userProfile;

  const [tabValue, setTabValue] = React.useState(EDIT_PROFILE_TAB);
  const [visible, setVisible] = React.useState(false);

  useEffect(() => {
    if (open) {
      setTabValue(EDIT_PROFILE_TAB);
      if (!personDialogInitialized) {
        setVisible(false);
      }
    }
  }, [open, personDialogInitialized]);

  useEffect(() => {
    if (tabValue === EDIT_PROFILE_TAB && personDialogInitialized) {
      setVisible(true);
    }
    if (tabValue === CHANGE_PASSWORD_TAB && changePasswordDialogInitialized) {
      setVisible(true);
    }
  }, [tabValue, personDialogInitialized, changePasswordDialogInitialized]);

  const handleSavePerson = useCallback(
    async (data: PersonInput) => {
      await dispatch(updateCurrentUserPersonalInfoThunk(data));
    },
    [dispatch],
  );

  const handleTabChange = useCallback(
    (_: React.SyntheticEvent, newValue: string) => {
      setTabValue(newValue);
      if (
        (newValue === EDIT_PROFILE_TAB && !personDialogInitialized) ||
        (newValue === CHANGE_PASSWORD_TAB && !changePasswordDialogInitialized)
      ) {
        setVisible(false);
      }
    },
    [personDialogInitialized, changePasswordDialogInitialized],
  );

  const tabPanelProps = {
    sx: {
      padding: 0,
    },
  };

  const editProfileLabel = useTranslation('SH.PG.EDITPROFILE');
  const changePasswordLabel = useTranslation('SH.PG.CHANGEPASSWORD');
  const tabsAriaLabel = useTranslation('SH.ARIA.EDITPROFILETABS');

  if (showError) {
    return null;
  }

  return (
    <>
      <Dialog
        id={EDIT_PROFILE_DIALOG_ID}
        fullWidth
        open={open}
        onClose={handleCancel}
        {...(!visible ? { sx: { display: 'none' } } : {})}
        PaperProps={{
          'aria-label': tabValue === EDIT_PROFILE_TAB ? editProfileLabel : changePasswordLabel,
        }}
      >
        <TabContext value={tabValue}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleTabChange} aria-label={tabsAriaLabel}>
              <Tab label={editProfileLabel} value={EDIT_PROFILE_TAB} />
              <Tab label={changePasswordLabel} value={CHANGE_PASSWORD_TAB} />
            </TabList>
          </Box>
          <TabPanel value={EDIT_PROFILE_TAB} {...tabPanelProps}>
            <PersonDialogFragment
              open={open}
              doInit={open}
              handleSave={handleSavePerson}
              handleCancel={handleCancel}
              getData={getPerson}
              person={userProfile}
              violationId={violationId}
              violationLevel={violationLevel}
              violationArgs={violationArgs}
              {...personDialogProps}
            />
          </TabPanel>
          <TabPanel value={CHANGE_PASSWORD_TAB} {...tabPanelProps}>
            <ChangePasswordDialogFragment
              open={open}
              doInit={open}
              handleCancel={handleCancel}
              getData={getChangePassword}
              {...changePasswordDialogProps}
            />
          </TabPanel>
        </TabContext>
      </Dialog>
    </>
  );
};
