import TextField from '@mui/material/TextField';
import React, { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router';

import { useAppDispatch, useAppSelector } from '../../App/hooks';
import {
  deleteUserProfileThunk,
  resetDeleteProfile,
  setPassword,
} from '../../features/user/deleteProfile/deleteProfileSlice';
import { selectDeleteProfile } from '../../features/user/deleteProfile/selectDeleteProfile';
import { PageFragmentInitProps, withPageFragmentInit } from '../../features/withPageFragmentInit';
import { ConfirmationDialog } from '../common/ConfirmationDialog';
import { FormAlert } from '../common/FormAlert';
import { FormMarkdown } from '../common/FormMarkdown';
import { FormPasswordField } from '../common/FormPasswordField';
import { ViolationBanner } from '../common/ViolationBanner';

interface DeleteAccountDialogComponentProps extends PageFragmentInitProps {
  dialogId: string;
  open: boolean;

  handleCancel(): void;
}

export const DeleteAccountDialogComponent: React.FC<DeleteAccountDialogComponentProps> = (props) => {
  const dispatch = useAppDispatch();
  const deleteProfile = useAppSelector(selectDeleteProfile);
  const navigate = useNavigate();

  const { dialogId, open, handleCancel, screenRef, actionRef } = props;

  const pageFragmentProps = {
    screenRef,
    actionRef,
  };

  const { token, email, password, navigateTo, violationLevel, violationId } = deleteProfile;

  useEffect(() => {
    if (navigateTo) {
      navigate(navigateTo, { replace: true });
      dispatch(resetDeleteProfile());
    }
  }, [navigate, navigateTo, dispatch]);

  const handleDelete = useCallback(async () => {
    dispatch(deleteUserProfileThunk({ token, password }));
  }, [dispatch, token, password]);

  const handlePasswordChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    dispatch(setPassword(event.target.value));
  };

  return (
    <ConfirmationDialog
      dialogId={dialogId}
      open={open}
      color="error"
      titleId="SH.PG.DLTPRFL"
      cancelId="SH.BTN.CANCEL"
      confirmId="SH.BTN.DELETEACCOUNT"
      onClose={handleCancel}
      onConfirm={handleDelete}
    >
      <FormAlert id="SH.DLTPRFL.ALERT" {...props} />
      {violationLevel && <ViolationBanner level={violationLevel} id={violationId} />}
      <FormMarkdown id="SH.DLTPRFL.ADDITIONALTEXT" />
      <TextField value={email || ''} sx={{ display: 'none' }} autoComplete="username" />
      <FormPasswordField
        id="SH.DLTPRFL.PASSWORD"
        margin="normal"
        fullWidth
        autoComplete="password"
        onChange={handlePasswordChange}
        value={password}
        {...pageFragmentProps}
      />
    </ConfirmationDialog>
  );
};

export const DeleteAccountDialog = withPageFragmentInit(DeleteAccountDialogComponent);
