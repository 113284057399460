import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { EnvironmentConfig, getAppConfig } from '../../App/apiWrapper';
import { OptionalMapType } from '../../App/types';
import { initialState } from '.';

export const getAppConfigThunk = createAsyncThunk<EnvironmentConfig>('app/config', async () => await getAppConfig());

export const config = createSlice({
  name: 'config',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAppConfigThunk.fulfilled, (state, action) => {
      const environmentConfig = action.payload;
      Object.keys(environmentConfig)
        .filter((key) => !['googleAnalytics'].includes(key))
        .forEach((key) => (state[key] = environmentConfig[key]));
      Object.keys(environmentConfig.googleAnalytics)
        .filter((key) => !['domainGoogleAnalytics'].includes(key))
        .forEach((key) => (state.googleAnalytics[key] = environmentConfig.googleAnalytics[key]));
      const domainMeasurementIds: OptionalMapType<string, string> = {};
      environmentConfig.googleAnalytics.domainGoogleAnalytics.forEach(
        ({ domain, measurementId }) => (domainMeasurementIds[domain] = measurementId),
      );
      state.googleAnalytics.domainMeasurementIds = domainMeasurementIds;
      state.initialized = true;
      return state;
    });
  },
});

export default config.reducer;
