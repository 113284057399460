import { getOverrides, MuiMarkdown } from 'mui-markdown';
import React, { useMemo } from 'react';

import { MuiSxProps as SxProps } from '../../../App/types/muiRestrictedTypes';
import { H4 } from './H4';
import { H5 } from './H5';
import { H6 } from './H6';
import { Paragraph } from './Paragraph';

interface OverrideProps {
  h4?: SxProps;
  h5?: SxProps;
  h6?: SxProps;
  p?: SxProps;
}

interface HelpTextMarkdownProps {
  id?: string;
  text: string;
  overrides?: OverrideProps;
}

export const HelpTextMarkdown: React.FC<HelpTextMarkdownProps> = (props) => {
  const { id, text, overrides = {} } = props;

  const baseStyles = useMemo(
    () => ({
      fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
      fontSize: '14px',
      lineHeight: '20px',
    }),
    [],
  );

  return (
    <span data-testid={`${id}-help-text-markdown`}>
      <MuiMarkdown
        overrides={{
          ...getOverrides(),
          ul: {
            component: 'ul',
            props: {
              style: {
                ...baseStyles,
                padding: '0 0 0 20px',
              },
            },
          },
          li: {
            component: 'li',
            props: {
              style: {
                ...baseStyles,
                padding: '4px 0',
              },
            },
          },
          h4: {
            component: H4,
            props: {
              style: {
                ...overrides.h4,
              },
            },
          },
          h5: {
            component: H5,
            props: {
              style: {
                ...overrides.h5,
              },
            },
          },
          h6: {
            component: H6,
            props: {
              style: {
                ...overrides.h6,
              },
            },
          },
          p: {
            component: Paragraph,
            props: {
              style: {
                ...overrides.p,
              },
            },
          },
          sup: {
            component: 'sup',
            props: {
              style: {
                ...baseStyles,
              },
            },
          },
        }}
      >
        {text}
      </MuiMarkdown>
    </span>
  );
};
