import { useState } from 'react';

import StorageService from '../services/StorageService';

export const useLocalStorage = (keyName, defaultValue) => {
  const [storedValue, setStoredValue] = useState(() => {
    return StorageService.getValue(keyName, defaultValue);
  });

  const setValue = (newValue) => {
    StorageService.setValue(keyName, newValue);
    setStoredValue(newValue);
  };

  return [storedValue, setValue];
};
