import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DisplayRuleKey, DisplayRulesOutput, getDisplayRulesOutput } from '../../App/apiWrapper';
import { DataState } from '../../App/types';
import { DataObject } from '../../helpers/displayRules';
import { applyDefaultMetadata } from '../../helpers/displayRules/applyDefaultMetadata';
import { applyDefaultState } from '../../helpers/displayRules/applyDefaultState';
import { applyDisplayRuleDefinitions } from '../../helpers/displayRules/applyDisplayRuleDefinitions';
import { getDisplayRuleKey } from '../../helpers/displayRules/getDisplayRuleKey';
import { displayRuleInitialState, DisplayRuleStateMap, initialState } from '.';

export const getDisplayRulesOutputThunk = createAsyncThunk<DisplayRulesOutput, DisplayRuleKey>(
  'displayRules/displayrulesoutput',
  async (key) => {
    return await getDisplayRulesOutput(key);
  },
);

export interface DisplayRuleInput<T> extends DisplayRuleKey {
  data: T;
}

const handleDisplayRuleOutput = (action: PayloadAction<DisplayRulesOutput>, state: DisplayRuleStateMap) => {
  const { screen, action: displayRuleAction, defaultState, metadata, displayRuleDefs } = action.payload;
  const key = getDisplayRuleKey(screen, displayRuleAction);
  state[key] = {
    defaultState,
    metadata,
    displayRuleDefs,
    dataState: DataState.INITIALIZED,
  };
};

export const displayRulesSlice = createSlice({
  name: 'displayRules',
  initialState,
  reducers: {
    runMetadataRules: (state, action: PayloadAction<DisplayRuleInput<DataObject>>) => {
      const { screen, action: displayRuleAction, data } = action.payload;
      const key = getDisplayRuleKey(screen, displayRuleAction);
      const metadata = state[key].metadata;
      state[key].currentMetadata = applyDefaultMetadata(metadata!, { ...data });
    },
    runStateRules: (state, action: PayloadAction<DisplayRuleInput<DataObject>>) => {
      const { screen, action: displayRuleAction, data } = action.payload;
      const key = getDisplayRuleKey(screen, displayRuleAction);
      const { defaultState, displayRuleDefs } = state[key];
      const newState = applyDefaultState(defaultState!, data);
      applyDisplayRuleDefinitions(newState, displayRuleDefs!, data);
      state[key].currentState = newState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDisplayRulesOutputThunk.pending, (state, action) => {
        const arg = action.meta.arg;
        const key = getDisplayRuleKey(arg.screen, arg.action);
        state[key] = {
          ...displayRuleInitialState,
          dataState: DataState.LOADING,
        };
      })
      .addCase(getDisplayRulesOutputThunk.fulfilled, (state, action) => {
        handleDisplayRuleOutput(action, state);
      });
  },
});

export const { runMetadataRules, runStateRules } = displayRulesSlice.actions;

export default displayRulesSlice.reducer;
