import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';

import { useAppSelector } from '../../App/hooks';
import { useTranslation } from '../../features/i18n/useTranslation';
import { selectUiState } from '../../features/ui/selectUiState';

export const AppSpinner: React.FC = () => {
  const { showLoader } = useAppSelector(selectUiState);
  const ariaLoading = useTranslation('SH.ARIA.LOADING');

  if (!showLoader) {
    return null;
  }

  return (
    <Backdrop role="presentation" aria-hidden={false} open={true} invisible={true} sx={{ zIndex: 999 }}>
      <CircularProgress aria-label={ariaLoading} color="inherit" />
    </Backdrop>
  );
};
