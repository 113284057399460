import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/system/useMediaQuery';
import React from 'react';

import { ReferenceData } from '../../../../App/apiWrapper';
import { theme } from '../../../../App/theme';
import { AnnualSign, I18nTypes } from '../../../../App/types';
import { useTranslation } from '../../../../features/i18n/useTranslation';
import { AnnualSignIcon } from './AnnualSignIcon';

interface AnnualSignWrapper {
  id: I18nTypes;
  value: ReferenceData;
}

export const AnnualSignWrapper: React.FC<AnnualSignWrapper> = (props) => {
  const {
    id,
    value: { id: annualSign, name },
  } = props;

  const matches = useMediaQuery(theme.breakpoints.up('md'));

  const translation = useTranslation(id);

  return (
    <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
      {matches && (
        <Typography component="span" variant="body2">
          {translation}:
        </Typography>
      )}
      <Tooltip title={matches ? name : `${translation}: ${name}`}>
        <span role="button" data-testid={id}>
          <AnnualSignIcon annualSign={annualSign as AnnualSign} />
        </span>
      </Tooltip>
    </Stack>
  );
};
