import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid2';
import React, { useCallback } from 'react';

import { useAppDispatch, useAppSelector } from '../../App/hooks';
import { selectUiState } from '../../features/ui/selectUiState';
import { setShowAboutUs } from '../../features/ui/uiSlice';
import { CloseIconButton } from '../common/CloseIconButton';
import { DefaultFormButton } from '../common/DefaultFormButton';
import { I18nFragment } from '../common/I18nFragment';
import { ResponsiveDialog } from '../common/responsive/ResponsiveDialog';
import { AboutUsContent } from './AboutUsContent';

export const ABOUT_US_DIALOG_ID = 'about-us-dialog';

export const AboutUsDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const { showAboutUs } = useAppSelector(selectUiState);

  const onClose = useCallback(() => {
    dispatch(setShowAboutUs(false));
  }, [dispatch]);

  return (
    <ResponsiveDialog
      id={ABOUT_US_DIALOG_ID}
      breakpoints={{ maxWidth: 'lg' }}
      fullWidth
      open={showAboutUs}
      aria-labelledby="about-us-dialog-title"
      onClose={onClose}
    >
      <DialogTitle id="about-us-dialog-title">
        <Grid
          container
          direction="row"
          sx={{
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Grid>
            <I18nFragment id="SH.PG.ABOUTUS" />
          </Grid>
          <Grid>
            <CloseIconButton onClick={onClose} />
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent
        style={{
          minHeight: '70vh',
        }}
      >
        <AboutUsContent />
      </DialogContent>
      <DialogActions>
        <DefaultFormButton id="SH.BTN.CLOSE" color="primary" onClick={onClose} />
      </DialogActions>
    </ResponsiveDialog>
  );
};
