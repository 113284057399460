import Typography from '@mui/material/Typography';
import React from 'react';

import { useAppSelector } from '../../../App/hooks';
import { selectAppConfig } from '../../../features/config/selectAppConfig';
import { I18nFragment } from '../../common/I18nFragment';
import { getYearDifference } from './getYearDifference';

export const Copyright: React.FC = () => {
  const { foundationYear } = useAppSelector(selectAppConfig);

  return (
    <Typography
      variant="body2"
      color="textSecondary"
      sx={{
        textAlign: 'right',
      }}
    >
      <I18nFragment id="SH.FTR.COPYRIGHT" args={[getYearDifference(foundationYear)]} />
    </Typography>
  );
};
