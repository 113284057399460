import { createSelector } from 'reselect';

import { ActionRef, I18nTypes, ScreenRef } from '../../App/types';
import { getDisplayRulesMetadata } from '../displayRules/getDisplayRulesMetadata';
import { selectDisplayRuleStateMap } from '../displayRules/selectDisplayRuleStateMap';
import { refDataValues } from './refDataValues';
import { selectScreenRefDataMap } from './selectScreenRefDataMap';

export const selectRefDataValues = (screen: ScreenRef, action: ActionRef, id: I18nTypes) =>
  createSelector([selectScreenRefDataMap, selectDisplayRuleStateMap], (screenRefDataMap, displayRules) => {
    const meta = getDisplayRulesMetadata(displayRules, screen, action);
    if (!meta || !meta[id]) {
      return [];
    }
    const fieldMeta = meta[id];
    return refDataValues(fieldMeta.codeTables, screenRefDataMap[screen]);
  });
