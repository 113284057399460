import CheckIcon from '@mui/icons-material/Check';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import React, { ReactElement } from 'react';

import { ReferenceData } from '../../../App/apiWrapper';
import { useTranslation } from '../../../features/i18n/useTranslation';
import { getRefDataAttributeValue } from '../../../features/refdata/getRefDataAttributeValue';
import { getViolationItems } from '../../../features/violations/getViolationItems';
import { contrast } from '../../../helpers/color/contrast';
import { clonePropertyInReactElementChildren } from '../../../helpers/react/clonePropertyInReactElementChildren';
import { FormMultiSelectProps } from './MultiSelectProps';

export const MultiSelectChip: React.FC<FormMultiSelectProps> = (props) => {
  const {
    options,
    value,
    onValueChange,
    variant,
    id,
    placeholder,
    showLabel = true,
    size,
    disabled,
    required,
    hasViolation,
    violationMessages,
  } = props;

  const placeholderText = useTranslation(placeholder);
  const labelText = useTranslation(id);
  const helperTextItems = getViolationItems(violationMessages);

  return (
    <Autocomplete
      multiple
      fullWidth
      id={id}
      options={options}
      getOptionLabel={(option) => option.name}
      value={value}
      readOnly={disabled}
      onChange={(_, newValue: ReferenceData[]) => {
        onValueChange(newValue);
      }}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => {
          const color = getRefDataAttributeValue(option, 'COLOR');
          const textColor = contrast(color);
          const tagProps = getTagProps({ index });
          const { onDelete } = tagProps;

          return (
            <Chip
              {...(size ? { size } : {})}
              sx={{
                backgroundColor: color,
                color: textColor,
                '& .MuiChip-deleteIcon': {
                  color: textColor,
                },
              }}
              label={option.name}
              onTouchEnd={onDelete}
              {...tagProps}
              key={option.id}
            />
          );
        })
      }
      isOptionEqualToValue={(lh, rh) => lh.id === rh.id}
      renderOption={(props, option, { selected }) => (
        <MenuItem value={option.name} sx={{ justifyContent: 'space-between' }} {...props} key={option.id}>
          {option.name}
          {selected ? <CheckIcon key={`${option.id}-selected`} color="info" /> : null}
        </MenuItem>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          variant={variant}
          disabled={disabled}
          required={required}
          {...(showLabel ? { label: labelText } : {})}
          placeholder={placeholderText}
          {...(hasViolation ? { error: true } : {})}
          helperText={<>{helperTextItems}</>}
          slotProps={{
            input: {
              ...params.InputProps,
              endAdornment: clonePropertyInReactElementChildren(
                params.InputProps.endAdornment as ReactElement,
                'onClick',
                'onTouchEnd',
              ),
            },
            htmlInput: {
              ...params.inputProps,
              ...(!showLabel ? { 'aria-label': labelText } : {}),
            },
          }}
        />
      )}
    />
  );
};
