import { Dayjs } from 'dayjs';

import { DateFormat, DateType } from '../../App/types';

/**
 * Formats date value according to the provided format.
 *
 * @param {Dayjs} value - The date value to be formatted.
 * @param {DateFormat} format - format to use for formatting.
 * @returns {DateType} The formatted date value as a string, or null if value is falsy.
 */
export const formatDate = (value: Dayjs | null, format: DateFormat): DateType => {
  return value && format ? value.format(format) : null;
};
