import { AlertColor } from '@mui/material/Alert';

import { ViolationLevel } from '../App/types';

export const getSeverity = (violationLevel: ViolationLevel): AlertColor => {
  switch (violationLevel) {
    case ViolationLevel.ERROR:
      return 'error';
    case ViolationLevel.WARNING:
      return 'warning';
    case ViolationLevel.INFO:
      return 'info';
  }
};
