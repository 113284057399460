import Button, { ButtonOwnProps } from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import React, { ReactNode } from 'react';

import { I18nTypes } from '../../App/types';
import { I18nFragment } from './I18nFragment';

export interface ConfirmationDialogProps {
  dialogId: string;
  titleId: I18nTypes;
  confirmId?: I18nTypes;
  cancelId?: I18nTypes;
  children: ReactNode;
  open: boolean;
  color?: ButtonOwnProps['color'];

  onClose(): void;

  onConfirm(): void;
}

export const ConfirmationDialog: React.FC<ConfirmationDialogProps> = (props) => {
  const {
    dialogId,
    titleId,
    confirmId = 'SH.BTN.YES',
    cancelId = 'SH.BTN.NO',
    children,
    open,
    color = 'primary',
    onClose,
    onConfirm,
  } = props;

  return (
    <Dialog id={dialogId} open={open} onClose={onClose} aria-labelledby="confirmation-dialog">
      <DialogTitle sx={{ fontWeight: 'bold' }} id="confirmation-dialog">
        <I18nFragment id={titleId} />
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button onClick={onClose} color={color}>
          <I18nFragment id={cancelId} />
        </Button>
        <Button variant="contained" onClick={onConfirm} color={color}>
          <I18nFragment id={confirmId} />
        </Button>
      </DialogActions>
    </Dialog>
  );
};
