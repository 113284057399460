import crawlerUserAgents from 'crawler-user-agents';

import { StringType } from '../../App/types';

const crawlerUserAgentsPatterns = crawlerUserAgents.map((bot) => new RegExp(bot.pattern, 'i'));

export const isBot = (userAgent?: StringType): boolean => {
  if (!userAgent) {
    return false;
  }
  return crawlerUserAgentsPatterns.some((pattern) => pattern.test(userAgent));
};
