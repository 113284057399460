import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  callDeleteUserProfile,
  callGetDeleteProfileRequest,
  DeleteProfileRequest,
  DeleteUserProfileInputDto,
  GenericOutput,
} from '../../../App/apiWrapper';
import { ViolationLevel } from '../../../App/types';
import { deleteCurrentUserAccountThunk } from '../userSlice';
import { initialState } from './index';

export const getDeleteAccountRequestThunk = createAsyncThunk<DeleteProfileRequest, string>(
  'deleteProfile/get',
  async (token) => await callGetDeleteProfileRequest(token),
);

export const deleteUserProfileThunk = createAsyncThunk<GenericOutput, DeleteUserProfileInputDto>(
  'deleteProfile/delete',
  async (input) => {
    return await callDeleteUserProfile(input);
  },
);

export const deleteProfileSlice = createSlice({
  name: 'deleteProfile',
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<string | undefined>) => {
      state.token = action.payload;
    },
    setEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    setPassword: (state, action: PayloadAction<string>) => {
      state.password = action.payload;
    },
    resetDeleteProfile: (state, _: PayloadAction) => {
      state.token = null;
      state.email = null;
      state.initialized = false;
      state.password = '';
      state.navigateTo = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteCurrentUserAccountThunk.fulfilled, (state, action) => {
        const response = action.payload;
        state.email = response.username;
        state.token = response.token;
        state.initialized = true;
      })
      .addCase(deleteUserProfileThunk.fulfilled, (state, action) => {
        const response = action.payload;
        const violations = response.violations;
        if (!violations || violations.length === 0) {
          state.violationLevel = null;
          state.violationId = null;
          state.navigateTo = '/delete_account_complete';
        }
      })
      .addCase(getDeleteAccountRequestThunk.fulfilled, (state, action) => {
        const response = action.payload;
        state.email = response.email;
        state.initialized = true;
      })
      .addCase(deleteUserProfileThunk.rejected, (state, _) => {
        state.violationLevel = ViolationLevel.ERROR;
        state.violationId = 'SH.COMMON.ER.001';
      })
      .addCase(getDeleteAccountRequestThunk.rejected, (state, _) => {
        state.email = null;
        state.initialized = true;
      });
  },
});

export const { setToken, setEmail, setPassword, resetDeleteProfile } = deleteProfileSlice.actions;

export default deleteProfileSlice.reducer;
