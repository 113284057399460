import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import React from 'react';

import { withDisplayRule, WithDisplayRuleProps } from '../../features/displayRules/withDisplayRule';
import { getViolationItems } from '../../features/violations/getViolationItems';
import { getSeverity } from '../../helpers/getSeverity';

interface FormAlertComponentProps extends WithDisplayRuleProps {}

export const FormAlertComponent: React.FC<FormAlertComponentProps> = (props) => {
  const { hasViolation, violationMessages, violationLevel } = props;

  if (!hasViolation || !violationLevel) {
    return null;
  }

  const severity = getSeverity(violationLevel);
  const alertItems = getViolationItems(violationMessages, '1rem');

  return (
    <Stack sx={{ width: '100%' }} spacing={2}>
      <Alert severity={severity}>{alertItems}</Alert>
    </Stack>
  );
};

export const FormAlert = withDisplayRule(FormAlertComponent);
