import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import React, { PropsWithChildren, Suspense } from 'react';

const LinearIndeterminate = () => (
  <Box sx={{ width: '100%' }}>
    <LinearProgress />
  </Box>
);

export const SuspenseWrapper: React.FC<PropsWithChildren<{}>> = ({ children }) => (
  <Suspense fallback={<LinearIndeterminate />}>{children}</Suspense>
);
