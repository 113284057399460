import React, { lazy } from 'react';
import { Navigate, useOutlet } from 'react-router';

import { useAppSelector } from '../../App/hooks';
import { withAppProviders } from '../../App/withAppProviders';
import { selectShowError } from '../../features/ui/selectShowError';
import { isAuthUserRouteExclude } from '../../helpers/isAuthUserRouteExclude';
import { useAuth } from '../../hooks/useAuth';
import { SuspenseWrapper as Suspense } from '../common/SuspenseWrapper';
import { AppLayout } from './AppLayout';

const ServiceErrorPage = lazy(() =>
  import('../pages/ServiceErrorPage').then(({ ServiceErrorPage }) => ({ default: ServiceErrorPage })),
);

const AppContent = lazy(() => import('./AppContent').then(({ AppContent }) => ({ default: AppContent })));

interface HomeLayoutProps {
  authUserRoute: string;
}

const HomeLayoutComponent: React.FC<HomeLayoutProps> = ({ authUserRoute }) => {
  const { userAuthenticated } = useAuth();
  const showError = useAppSelector(selectShowError);
  const outlet = useOutlet();
  const { pathname } = location;

  if (userAuthenticated && !isAuthUserRouteExclude(pathname)) {
    return <Navigate to={authUserRoute} replace />;
  }

  const content = showError ? (
    <Suspense>
      <ServiceErrorPage />
    </Suspense>
  ) : (
    <Suspense>
      <AppContent content={outlet} />
    </Suspense>
  );

  return <AppLayout content={content} />;
};

export const HomeLayout = withAppProviders(HomeLayoutComponent);
