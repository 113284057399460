import Link from '@mui/material/Link';
import React, { useCallback } from 'react';

import { useAppDispatch } from '../../../App/hooks';
import { setShowPrivacyPolicy } from '../../../features/ui/uiSlice';
import { I18nFragment } from '../../common/I18nFragment';
import { PRIVACY_POLICY_DIALOG_ID } from '../../privacyPolicy/PrivacyPolicyDialog';

export const PrivacyPolicyLink: React.FC = () => {
  const dispatch = useAppDispatch();

  const showPrivacyPolicy = useCallback(() => {
    dispatch(setShowPrivacyPolicy(true));
  }, [dispatch]);

  return (
    <Link
      variant="body2"
      component="button"
      aria-controls={PRIVACY_POLICY_DIALOG_ID}
      aria-haspopup="true"
      onClick={showPrivacyPolicy}
    >
      <I18nFragment id="SH.BTN.PRIVACYPOLICY" />
    </Link>
  );
};
