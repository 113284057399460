import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import React from 'react';

import { useAppSelector } from '../../App/hooks';
import { I18nTypes, ViolationLevel } from '../../App/types';
import { i18n } from '../../features/i18n/i18n';
import { selectTranslationEntries } from '../../features/i18n/selectTranslationEntries';
import { getSeverity } from '../../helpers/getSeverity';

interface ViolationBannerProps {
  level: ViolationLevel;
  id?: I18nTypes | null;
  args?: string[];
}

export const ViolationBanner: React.FC<ViolationBannerProps> = (props) => {
  const translationEntries = useAppSelector(selectTranslationEntries);

  const { level, id, args } = props;

  const severity = getSeverity(level);
  const violationId = id ? id : 'SH.COMMON.ER.001';
  const violationMessage = i18n(violationId, translationEntries, ...(args || []));

  return (
    <Stack sx={{ width: '100%' }} spacing={2}>
      <Alert severity={severity}>{violationMessage}</Alert>
    </Stack>
  );
};
