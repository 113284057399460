import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid2';
import Stack from '@mui/material/Stack';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { useCallback } from 'react';

import { useAppDispatch, useAppSelector } from '../../App/hooks';
import { theme } from '../../App/theme';
import { TermsOfUseStatus } from '../../App/types';
import { selectTermsOfUseStatus } from '../../features/ui/selectTermsOfUseStatus';
import { selectUiState } from '../../features/ui/selectUiState';
import { setShowTermsOfUse } from '../../features/ui/uiSlice';
import { CloseIconButton } from '../common/CloseIconButton';
import { FormMarkdown } from '../common/FormMarkdown';
import { I18nFragment } from '../common/I18nFragment';
import { ResponsiveDialog } from '../common/responsive/ResponsiveDialog';
import { TermsOfUseChangedAlert } from './TermsOfUseChangedAlert';
import { TermsOfUseContent } from './TermsOfUseContent';
import { TermsOfUseDialogActions } from './TermsOfUseDialogActions';

export const TERMS_OF_USE_DIALOG_ID = 'terms-of-use-dialog';

export const TermsOfUseDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const { showTermsOfUse } = useAppSelector(selectUiState);
  const termsOfUseStatus = useAppSelector(selectTermsOfUseStatus);

  const onClose = useCallback(() => {
    dispatch(setShowTermsOfUse(false));
  }, [dispatch]);

  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <ResponsiveDialog
      id={TERMS_OF_USE_DIALOG_ID}
      breakpoints={{ maxWidth: 'lg' }}
      fullWidth
      open={showTermsOfUse}
      aria-labelledby="terms-of-use-dialog-title"
      onClose={onClose}
    >
      <DialogTitle id="terms-of-use-dialog-title" component="h3">
        <Grid
          container
          direction="row"
          sx={{
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Grid
            {...(!matches
              ? {
                  sx: {
                    fontSize: '1.15rem',
                  },
                }
              : {})}
          >
            <I18nFragment id="SH.PG.TERMSOFUSE" />
          </Grid>
          <Grid>
            <CloseIconButton onClick={onClose} />
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent
        style={{
          minHeight: '70vh',
        }}
      >
        <Stack>
          {termsOfUseStatus === TermsOfUseStatus.OLD_VERSION_ACCEPTED && (
            <TermsOfUseChangedAlert severity="info">
              <FormMarkdown
                id="SH.TOU.IR.001"
                overrides={{
                  h4: { alignItems: 'flex-start' },
                  h5: { alignItems: 'flex-start' },
                  h6: { alignItems: 'flex-start' },
                  p: { alignItems: 'flex-start' },
                }}
              />
            </TermsOfUseChangedAlert>
          )}
          <TermsOfUseContent />
        </Stack>
      </DialogContent>
      <TermsOfUseDialogActions onDisagree={onClose} />
    </ResponsiveDialog>
  );
};
