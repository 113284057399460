import React, { lazy } from 'react';
import { Navigate, useOutlet } from 'react-router';

import { useAppSelector } from '../../App/hooks';
import { TermsOfUseStatus } from '../../App/types';
import { withAppProviders } from '../../App/withAppProviders';
import { selectShowError } from '../../features/ui/selectShowError';
import { selectTermsOfUseStatus } from '../../features/ui/selectTermsOfUseStatus';
import { withUser } from '../../features/user/withUser';
import { useAuth } from '../../hooks/useAuth';
import { SuspenseWrapper as Suspense } from '../common/SuspenseWrapper';
import { AppLayout } from './AppLayout';

const ServiceErrorPage = lazy(() =>
  import('../pages/ServiceErrorPage').then(({ ServiceErrorPage }) => ({ default: ServiceErrorPage })),
);

const AppContent = lazy(() => import('./AppContent').then(({ AppContent }) => ({ default: AppContent })));

const TermsOfUsePage = lazy(() =>
  import('../termsOfUse/TermsOfUsePage').then(({ TermsOfUsePage }) => ({ default: TermsOfUsePage })),
);

interface ProtectedLayoutProps {
  anonymousUserRoute: string;
}

const ProtectedLayoutComponent: React.FC<ProtectedLayoutProps> = ({ anonymousUserRoute }) => {
  const { userAuthenticated } = useAuth();
  const showError = useAppSelector(selectShowError);
  const termsOfUseStatus = useAppSelector(selectTermsOfUseStatus);
  const outlet = useOutlet();

  if (!userAuthenticated) {
    return <Navigate to={anonymousUserRoute} />;
  }

  const content = showError ? (
    <Suspense>
      <ServiceErrorPage />
    </Suspense>
  ) : termsOfUseStatus === TermsOfUseStatus.ACCEPTED ? (
    <Suspense>
      <AppContent content={outlet} />
    </Suspense>
  ) : (
    <Suspense>
      <TermsOfUsePage />
    </Suspense>
  );

  return <AppLayout content={content} />;
};

export const ProtectedLayout = withAppProviders(withUser(ProtectedLayoutComponent));
