import React from 'react';

import { I18nTypes } from '../../App/types';
import { useTranslation } from '../../features/i18n/useTranslation';

interface FragmentProps {
  id: I18nTypes;
  args?: string[];
}

export const I18nFragment: React.FC<FragmentProps> = ({ id, args }) => {
  const message = useTranslation(id, args);

  return <>{message}</>;
};
